import Button from 'components/Button';
import { FaInfoCircle } from 'react-icons/fa';

interface ISectionProps {
    title: string;
    description?: string;
    readMore?: () => void;
    children?: React.ReactNode;
}
export default function Section({ title, description, readMore, children }: ISectionProps) {
    return (
        <div className="flex gap-6">
            <div className="w-full">
                <div className="flex gap-4 items-center">
                    <h3 className="text-base font-medium">{title}</h3>
                    {readMore && (
                        <Button variant="text" onClick={() => readMore()}>
                            <FaInfoCircle size={18} />
                        </Button>
                    )}
                </div>

                <p className="text-gray-500 font-normal text-sm">{description}</p>
            </div>
            {children && <div className="w-full">{children}</div>}
        </div>
    );
}
