import { useState, useRef, useEffect } from 'react';
import ReactCrop, { Crop, PixelCrop } from 'react-image-crop';
import canvasPreview from 'helpers/canvasPreview';
import Dropzone from 'components/Dropzone/Dropzone';

// Debounce
function debounce(func: EventListener, time: number = 100) {
    var timer: any;
    return function (event: Event) {
        if (timer) clearTimeout(timer);
        timer = setTimeout(func, time, event);
    };
}

interface ImagePickerProps {
    onImageSelected?: (imageUrl: string) => void;
    onFileSelected?: (file: File) => void;
    onSave: (file: File) => void;
    defaultImageUrl?: string;
    previewCanvasRef: React.RefObject<HTMLCanvasElement>;
    isEditingImage: boolean;
}

export default function ImagePicker({ previewCanvasRef, isEditingImage = false }: ImagePickerProps) {
    const imageRef = useRef<HTMLImageElement>(null);
    const [imageSize, setImageSize] = useState({ width: 0, height: 0 });
    const [imageUrl, setImageUrl] = useState<string | undefined>();

    const [crop, setCrop] = useState<Crop>();
    const [completedCrop, setCompletedCrop] = useState<PixelCrop>();
    //

    function resetCrop() {
        setCrop(undefined);
        setCompletedCrop(undefined);
    }

    useEffect(() => {
        if (!isEditingImage) {
            resetCrop();
        }
    }, [isEditingImage]);

    useEffect(() => {
        function resizeContent() {
            resetCrop();
        }
        const debouncedResizeContent = debounce(resizeContent, 200);
        window.addEventListener('resize', debouncedResizeContent);

        return () => window.removeEventListener('resize', debouncedResizeContent);
    }, []);

    useEffect(() => {
        if (!imageUrl) {
            return;
        }
        const img = new Image();

        img.src = imageUrl;
        img.onload = () => {
            setImageSize({
                width: img.width,
                height: img.height,
            });
        };
    }, [imageUrl]);

    useEffect(() => {
        if (!completedCrop || !previewCanvasRef.current || !imageRef.current) {
            return;
        }

        canvasPreview(imageRef.current, previewCanvasRef.current, completedCrop);
    }, [completedCrop, imageRef, previewCanvasRef]);

    function getImgDestSize() {
        const scale = imageSize.width! / imageRef.current?.offsetWidth!;

        if (completedCrop) {
            return {
                width: parseInt((completedCrop.width * scale).toFixed(0)),
                height: parseInt((completedCrop.height * scale).toFixed(0)),
            };
        }
        return imageSize;
    }
    return (
        <>
            <div className="flex flex-col gap-4">
                {isEditingImage && !imageUrl && (
                    <div style={{ flex: 1 }}>
                        <Dropzone onImageSelected={(url) => setImageUrl(url)} />
                    </div>
                )}
                {isEditingImage && imageUrl && (
                    <div className="flex gap-4">
                        <div>
                            <p style={{ margin: 0, padding: 0 }}>
                                {getImgDestSize().width} x {getImgDestSize().height} px
                            </p>
                            {parseFloat((getImgDestSize().width / getImgDestSize().height).toFixed(2)) === 2 &&
                                getImgDestSize().width < 1200 && (
                                    <p style={{ margin: 0, padding: 0, fontSize: 12 }}>
                                        Bilden kommer att skalas upp till 1200 x 600
                                    </p>
                                )}
                            {parseFloat((getImgDestSize().width / getImgDestSize().height).toFixed(2)) === 2 &&
                                getImgDestSize().width > 1200 && (
                                    <p style={{ margin: 0, padding: 0, fontSize: 12 }}>
                                        Bilden kommer att skalas ner till 1200 x 600
                                    </p>
                                )}
                            {parseFloat((getImgDestSize().width / getImgDestSize().height).toFixed(2)) !== 2 && (
                                <p style={{ margin: 0, padding: 0, fontSize: 12, color: 'red' }}>
                                    Bilden behöver ha dimension 2:1, Klicka och dra på bilden för att beskära
                                </p>
                            )}
                        </div>

                        {/* <Button onClick={saveImage}>Spara bild</Button> */}
                    </div>
                )}
                {imageUrl && (
                    <div style={{ position: 'relative' }}>
                        <ReactCrop
                            aspect={2 / 1}
                            crop={crop}
                            onChange={(c) => setCrop(c)}
                            onComplete={(c) => {
                                if (c.width && c.height) {
                                    setCompletedCrop(c);
                                } else {
                                    setCompletedCrop(undefined);
                                }
                            }}
                        >
                            <img
                                src={imageUrl}
                                ref={imageRef}
                                style={{ width: '100%', maxHeight: '400px' }}
                                alt="Klubbens bild"
                            />
                        </ReactCrop>
                    </div>
                )}
            </div>
            {completedCrop && (
                <canvas
                    ref={previewCanvasRef}
                    style={{
                        border: '1px solid black',
                        objectFit: 'contain',
                        opacity: 0,
                        position: 'absolute',
                        left: -10000,
                        width: completedCrop.width,
                        height: completedCrop.height,
                    }}
                />
            )}
        </>
    );
}
