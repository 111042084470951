import { Outlet, Navigate } from 'react-router-dom';
import { useAuth, AuthStatus } from '../context/AuthContext';
import AuthenticatedDefaultLayout from './AuthenticatedDefaultLayout';
import AuthenticatedEmbeddedLayout from './AuthenticatedEmbeddedLayout';

import { useLayout } from 'context/LayoutContext';
import { AuthenticatedUserContext } from 'context/AuthenticatedUserContext';

interface RequireAuthProps {
    requireRole?: string;
}

export default function RequireAuth({ requireRole }: RequireAuthProps) {
    const { authState } = useAuth();
    const { isEmbedded } = useLayout();

    const AuthenticatedLayout = isEmbedded ? AuthenticatedEmbeddedLayout : AuthenticatedDefaultLayout;

    if (authState.status === AuthStatus.SIGNED_OUT) {
        return <Navigate to="/login" replace />;
    }

    if (
        authState.status === AuthStatus.SIGNED_IN &&
        (!requireRole || (requireRole && authState.roles?.includes(requireRole)))
    ) {
        return (
            <AuthenticatedUserContext.Provider value={{ user: authState.user, roles: authState.roles }}>
                <AuthenticatedLayout>
                    <Outlet />
                </AuthenticatedLayout>
            </AuthenticatedUserContext.Provider>
        );
    }

    if (
        authState.status === AuthStatus.SIGNED_IN &&
        authState.user &&
        requireRole &&
        !authState.roles?.includes(requireRole)
    ) {
        return (
            <AuthenticatedUserContext.Provider value={{ user: authState.user, roles: authState.roles }}>
                <AuthenticatedLayout>
                    <p>Du har inte behörighet till att komma åt denna sida</p>
                    <p>{requireRole} role required</p>
                </AuthenticatedLayout>
            </AuthenticatedUserContext.Provider>
        );
    }

    return <></>;
}
