import { useState, useRef, useEffect } from 'react';
import './Dropzone.css';
import Button from 'components/Button';

interface IDropzoneProps {
    onImageSelected?: (imageUrl: string) => void;
    onFileSelected?: (file: File) => void;
}
export default function Dropzone({ onImageSelected, onFileSelected }: IDropzoneProps) {
    const [dragActive, setDragActive] = useState(false);
    const [file, setFile] = useState<File | undefined>(undefined);

    const imageMimeType = /image\/(png|jpg|jpeg)/i;

    const inputRef = useRef<HTMLInputElement>(null);

    // handle drag events
    const handleDrag = function (e: React.DragEvent<HTMLFormElement | HTMLDivElement>) {
        e.preventDefault();
        e.stopPropagation();
        if (e.type === 'dragenter' || e.type === 'dragover') {
            setDragActive(true);
        } else if (e.type === 'dragleave') {
            setDragActive(false);
        }
    };

    const handleDrop = function (e: React.DragEvent<HTMLFormElement | HTMLDivElement>) {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);
        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            // at least one file has been dropped so do something
            handleFiles(e.dataTransfer.files);
        }
    };

    function handleFiles(files: FileList) {
        const file = files[0];

        if (!file.type.match(imageMimeType)) {
            alert('Image mime type is not valid');
            return;
        }

        setFile(file);
        onFileSelected && onFileSelected(file);
    }

    useEffect(() => {
        const reader = new FileReader();
        let isCancel = false;
        if (file) {
            reader.onload = (e) => {
                const result = e?.target?.result;

                if (result && !isCancel) {
                    onImageSelected && onImageSelected(result as string);
                }
            };
            reader.readAsDataURL(file);
        }
        return () => {
            isCancel = true;
            if (reader && reader.readyState === 1) {
                reader.abort();
            }
        };
    }, [file, onImageSelected]);

    // triggers when file is selected with click
    const handleChange = function (e: React.ChangeEvent<HTMLInputElement>) {
        e.preventDefault();
        if (e.target.files && e.target.files[0]) {
            // at least one file has been selected so do something
            handleFiles(e.target.files);
        }
    };

    const onButtonClick = () => {
        inputRef?.current?.click();
    };

    return (
        <form className="dropzone-form" onDragEnter={handleDrag} onSubmit={(e) => e.preventDefault()}>
            <input
                ref={inputRef}
                type="file"
                id="input-file-upload"
                className="dropzone-form__input"
                multiple={false}
                onChange={handleChange}
                accept=".png, .jpg, .jpeg"
            />
            <label
                htmlFor="input-file-upload"
                className={`dropzone-form__input-label ${dragActive ? 'drag-active' : ''}`}
            >
                <div
                    style={{
                        alignItems: 'center',
                        justifyContent: 'center',
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <p className="dropzone-form__input-label-text">Dra och släpp en bild här eller</p>
                    <Button onClick={onButtonClick}>Välj en bild från enhet</Button>
                </div>
            </label>
            {dragActive && (
                <div
                    id="drag-file-element"
                    className="dropzone-form__drag-file-surface"
                    onDragEnter={handleDrag}
                    onDragLeave={handleDrag}
                    onDragOver={handleDrag}
                    onDrop={handleDrop}
                ></div>
            )}
        </form>
    );
}
