import ApiClient from 'api/ApiClient';
// import ClubDashboardType from 'api/openapi-club/models/ClubDashboardType';
import { ClubDashboardType } from 'api/openapi-club';

import { useQuery } from '@tanstack/react-query';

export default function StatisticsPage() {
    const { isLoading, data } = useQuery(
        ['clubdashboard', ClubDashboardType.BOOKINGS],
        () =>
            ApiClient.gsStatistics.postClubPortalStatisticsDashboard({
                dashboard: 'bookings' as ClubDashboardType,
                from: '2022-01-01T00:00:19.527Z',
                to: '2022-12-31T14:30:19.527Z',
                courseIds: [0],
            }),
        {},
    );
    return (
        <>
            <h1>Insikter i år</h1>
            {isLoading && <p>Loading...</p>}
            {data && data.url && (
                <div
                    style={{
                        display: 'flex',
                        flex: 1,
                    }}
                >
                    <iframe src={data.url} title="Statistics" style={{ flex: 1 }} />
                </div>
            )}
        </>
    );
}
