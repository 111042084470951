/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GsBookingSegment } from '../models/GsBookingSegment';
import type { GsCreateClubPushNotificationConfigRequest } from '../models/GsCreateClubPushNotificationConfigRequest';
import type { GsPushNotificationConfigResponse } from '../models/GsPushNotificationConfigResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class GsNotificationService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @param take
     * @param skip
     * @returns GsPushNotificationConfigResponse Success
     * @throws ApiError
     */
    public getClubPortalNotificationsConfigs(
        take: number = 10,
        skip?: number,
    ): CancelablePromise<Array<GsPushNotificationConfigResponse>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/club-portal/notifications/configs',
            query: {
                'take': take,
                'skip': skip,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }

    /**
     * @returns GsPushNotificationConfigResponse Success
     * @throws ApiError
     */
    public getClubPortalNotificationsAutomated(): CancelablePromise<Array<GsPushNotificationConfigResponse>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/club-portal/notifications/automated',
            errors: {
                400: `Bad Request`,
            },
        });
    }

    /**
     * @param requestBody
     * @returns GsPushNotificationConfigResponse Success
     * @throws ApiError
     */
    public postClubPortalNotificationsConfig(
        requestBody?: GsCreateClubPushNotificationConfigRequest,
    ): CancelablePromise<GsPushNotificationConfigResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/club-portal/notifications/config',
            body: requestBody,
            mediaType: 'application/json-patch+json',
            errors: {
                400: `Bad Request`,
            },
        });
    }

    /**
     * @param requestBody
     * @returns number Success
     * @throws ApiError
     */
    public postClubPortalNotificationsRecievers(
        requestBody?: GsBookingSegment,
    ): CancelablePromise<number> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/club-portal/notifications/recievers',
            body: requestBody,
            mediaType: 'application/json-patch+json',
            errors: {
                400: `Bad Request`,
            },
        });
    }

    /**
     * @param configId
     * @returns any Success
     * @throws ApiError
     */
    public deleteClubPortalNotificationsConfig(
        configId: number,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/club-portal/notifications/config/{configId}',
            path: {
                'configId': configId,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }

}
