/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GsClubDto } from '../models/GsClubDto';
import type { GsCourseDto } from '../models/GsCourseDto';
import type { GsUpdateClubRequest } from '../models/GsUpdateClubRequest';
import type { GsUploadImageResponse } from '../models/GsUploadImageResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class GsClubService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns GsClubDto Success
     * @throws ApiError
     */
    public getClubPortalClub(): CancelablePromise<GsClubDto> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/club-portal/club',
            errors: {
                404: `Not Found`,
            },
        });
    }

    /**
     * @param id
     * @returns GsClubDto Success
     * @throws ApiError
     */
    public getClubPortalClub1(
        id: number,
    ): CancelablePromise<GsClubDto> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/club-portal/club/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `Not Found`,
            },
        });
    }

    /**
     * @param id
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public putClubPortalClub(
        id: number,
        requestBody?: GsUpdateClubRequest,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/club-portal/club/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json-patch+json',
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
            },
        });
    }

    /**
     * @returns GsCourseDto Success
     * @throws ApiError
     */
    public getClubPortalClubCourses(): CancelablePromise<Array<GsCourseDto>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/club-portal/club/courses',
            errors: {
                404: `Not Found`,
            },
        });
    }

    /**
     * @param clubId
     * @param formData
     * @returns GsUploadImageResponse Success
     * @throws ApiError
     */
    public postClubPortalClubImage(
        clubId: number,
        formData?: {
            file?: Blob;
        },
    ): CancelablePromise<GsUploadImageResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/club-portal/club/{clubId}/image',
            path: {
                'clubId': clubId,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                404: `Not Found`,
            },
        });
    }

}
