/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseHttpRequest } from './core/BaseHttpRequest';
import type { OpenAPIConfig } from './core/OpenAPI';
import { FetchHttpRequest } from './core/FetchHttpRequest';

import { GsAccountService } from './services/GsAccountService';
import { GsClubService } from './services/GsClubService';
import { GsCourseService } from './services/GsCourseService';
import { GsDynamicPricingService } from './services/GsDynamicPricingService';
import { GsGlobalMessagesService } from './services/GsGlobalMessagesService';
import { GsNotificationService } from './services/GsNotificationService';
import { GsStatisticsService } from './services/GsStatisticsService';

type HttpRequestConstructor = new (config: OpenAPIConfig) => BaseHttpRequest;

export class ClubClient {

    public readonly gsAccount: GsAccountService;
    public readonly gsClub: GsClubService;
    public readonly gsCourse: GsCourseService;
    public readonly gsDynamicPricing: GsDynamicPricingService;
    public readonly gsGlobalMessages: GsGlobalMessagesService;
    public readonly gsNotification: GsNotificationService;
    public readonly gsStatistics: GsStatisticsService;

    public readonly request: BaseHttpRequest;

    constructor(config?: Partial<OpenAPIConfig>, HttpRequest: HttpRequestConstructor = FetchHttpRequest) {
        this.request = new HttpRequest({
            BASE: config?.BASE ?? 'https://api.golfersweden.com',
            VERSION: config?.VERSION ?? 'club',
            WITH_CREDENTIALS: config?.WITH_CREDENTIALS ?? false,
            CREDENTIALS: config?.CREDENTIALS ?? 'include',
            TOKEN: config?.TOKEN,
            USERNAME: config?.USERNAME,
            PASSWORD: config?.PASSWORD,
            HEADERS: config?.HEADERS,
            ENCODE_PATH: config?.ENCODE_PATH,
        });

        this.gsAccount = new GsAccountService(this.request);
        this.gsClub = new GsClubService(this.request);
        this.gsCourse = new GsCourseService(this.request);
        this.gsDynamicPricing = new GsDynamicPricingService(this.request);
        this.gsGlobalMessages = new GsGlobalMessagesService(this.request);
        this.gsNotification = new GsNotificationService(this.request);
        this.gsStatistics = new GsStatisticsService(this.request);
    }
}

