import { ReactNode } from 'react';
import './Select.css';
import classnames from 'classnames';
interface ISelectProps {
    value?: string | undefined | null;
    onChange?: (value: string) => void;
    onBlur?: (value: string) => void;
    disabled?: boolean;
    name: string;
    defaultValue?: string | number | undefined;
    placeholder?: string;
    label?: string | JSX.Element;
    children: ReactNode[] | ReactNode;
}

export default function Select({
    value,
    onChange = () => {},
    onBlur = () => {},
    disabled = false,
    name,
    defaultValue,
    label,
    placeholder,
    children,
}: ISelectProps) {
    const labelComponent = typeof label === 'string' ? <label className="select__label">{label}</label> : label;
    return (
        <div className="select">
            {label && labelComponent}

            <select
                className={classnames('select__control', disabled && 'select__control--disabled')}
                disabled={disabled}
                placeholder={placeholder}
                defaultValue={defaultValue}
                name={name}
                value={value ? value : undefined}
                onBlur={(e) => onBlur(e.currentTarget.value)}
                onChange={(e) => onChange(e.currentTarget.value)}
            >
                {children}
            </select>
        </div>
    );
}
