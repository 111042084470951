import { Auth, CognitoUser } from '@aws-amplify/auth';
import { Hub, HubCapsule } from '@aws-amplify/core';
import { queryClient } from 'App';
const { REACT_APP_IDENTITY_POOL_ID, REACT_APP_REGION, REACT_APP_USERPOOL_ID, REACT_APP_CLIENT_ID } = process.env;

enum EventType {
    onSignIn,
    onSignOut,
}

function initialize() {
    try {
        Auth.configure({
            identityPoolId: REACT_APP_IDENTITY_POOL_ID,
            region: REACT_APP_REGION,
            userPoolId: REACT_APP_USERPOOL_ID,
            userPoolWebClientId: REACT_APP_CLIENT_ID,
        });
    } catch (error) {
        throw error;
    }
}

async function hasCurrentSession() {
    try {
        await Auth.currentAuthenticatedUser();
        return true;
    } catch {
        return false;
    }
}

async function getUserRoles() {
    const session = await Auth.currentSession();
    return session.getAccessToken().payload['cognito:groups'];
}

async function getCurrentUserAccessToken() {
    const session = await Auth.currentSession();
    return session?.getAccessToken().getJwtToken();
}

async function signIn(username: string, password: string) {
    try {
        return await Auth.signIn(username, password);
    } catch (error) {
        throw error;
    }
}

async function completeNewPassword(user: CognitoUser, password: string) {
    try {
        await Auth.completeNewPassword(user, password);
    } catch (error) {
        throw error;
    }
}

async function resendConfirmationCode(username: string) {
    try {
        await Auth.resendSignUp(username);
    } catch (error) {
        throw error;
    }
}

async function signOut() {
    try {
        await Auth.signOut();
        queryClient.clear();
    } catch (error) {
        throw error;
    }
}

async function forgotPassword(username: string) {
    try {
        await Auth.forgotPassword(username);
    } catch (error) {
        throw error;
    }
}
async function forgotPasswordSubmit({
    username,
    password,
    code,
}: {
    username: string;
    password: string;
    code: string;
}) {
    try {
        await Auth.forgotPasswordSubmit(username, code, password);
    } catch (error) {
        throw error;
    }
}

function cognitoSignInListner({ payload: { event } }: HubCapsule, callback: () => void) {
    switch (event) {
        case 'signIn':
            callback();
            break;
    }
}

function cognitoSignOutListner({ payload: { event } }: HubCapsule, callback: () => void) {
    switch (event) {
        case 'signOut':
        case 'signIn_failure':
            callback();
            break;
    }
}

function addListener(eventType: EventType, callback: () => void) {
    function handleAuthChangeEvent(capsule: HubCapsule) {
        switch (eventType) {
            case EventType.onSignIn:
                cognitoSignInListner(capsule, callback);
                break;
            case EventType.onSignOut:
                cognitoSignOutListner(capsule, callback);
                break;
        }
    }

    Hub.listen('auth', handleAuthChangeEvent);

    return handleAuthChangeEvent;
}

function removeListner(fn: (capsule: HubCapsule) => void) {
    Hub.remove('auth', fn);
}

const AuthService = {
    signIn,
    signOut,
    forgotPassword,
    forgotPasswordSubmit,
    completeNewPassword,
    resendConfirmationCode,
    getCurrentUserAccessToken,
    getUserRoles,
    hasCurrentSession,
    addListener,
    removeListner,

    EventType,
};

export default AuthService;

initialize();
