import MgBokningIcon from 'assets/mgbokning-icon-76x76@2x.png';
import './Notification.css';

interface NotificationProps {
    title?: string;
    message?: string;
}

// const IOS_TRUNCATE_LIMIT: number = 107;

export default function Notification({ title, message }: NotificationProps) {
    return (
        <div className="notification">
            <div style={{ display: 'flex', gap: '0.8em' }}>
                <img
                    className="notification__header__icon"
                    src={MgBokningIcon}
                    alt="MgBokning"
                    width={36}
                    height={36}
                />
                <div>
                    <p className="notification__title">{title}</p>
                    <p className="notification__message truncate-overflow">{message}</p>
                </div>
            </div>
        </div>
    );
}
