/**
 *
 * @param date - The date to parse
 * @returns string - The date with timezone offset added in the format of YYYY-MM-DD HH:MM:SS
 */
function getLocalizedDateTimeString(date: Date): string {
    return new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000).toISOString().slice(0, 19).replace('T', ' ');
}

/**
 * @param date - The date to parse
 * @returns string - The date with timezone offset added in the format of 12 okt. kl 12:00
 */
function getReadableDateTimeString(date: Date): string {
    return `${date.toLocaleString('sv-SE', {
        dateStyle: 'medium',
    })} kl. ${date.toLocaleTimeString('sv-SE', {
        timeStyle: 'short',
    })}`;
}

const exports = {
    getLocalizedDateTimeString,
    getReadableDateTimeString,
};

export default exports;
