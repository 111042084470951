import { useRef, useEffect } from 'react';
import { GsCourseDto, GsUpdateCourseRequest } from 'api/openapi-club';
import Input from 'components/Input';
import Select from 'components/Select';
import Switch from 'components/Switch';
import Button from 'components/Button';
import useForm from 'hooks/useForm';
import Header from 'components/Header';
import Map, { IMapHandle } from 'components/Map';
import 'layout/Layout.css';

interface ICourseSettingsFormProps {
    onSubmit: ({ id, payload }: { id: number; payload: GsUpdateCourseRequest }) => void;

    course: GsCourseDto;
    isSubmitting?: boolean;
    isEdit?: boolean;
}

export default function CourseSettingsForm({ course, onSubmit }: ICourseSettingsFormProps) {
    const mapRef = useRef<IMapHandle>(null);
    const { formValues, handleSubmit, handleValueChange, isDirty, errors, reset } = useForm(
        {
            id: course.id,
            sorting: course.sorting,
            showInApp: course.showInApp,
            bookable: course.bookable,
            bookableMessage: course.bookableMessage,
            longitude: course.longitude,
            latitude: course.latitude,
        },
        {
            onSubmit: async () => {
                onSubmit({
                    id: course?.id!,
                    payload: {
                        ...formValues,
                        sorting: formValues.sorting ? formValues.sorting : undefined,
                        longitude: formValues.longitude ? parseFloat(formValues.longitude) : undefined,
                        latitude: formValues.latitude ? parseFloat(formValues.latitude) : undefined,
                        bookableMessage: formValues.bookable ? undefined : formValues.bookableMessage,
                    },
                });
            },
            validation: {
                longitude: {
                    nullable: false,
                    tests: [
                        {
                            func: (value) => {
                                if (value === '') {
                                    return true;
                                }

                                const parsed = parseFloat(value);
                                return !isNaN(value) && parsed >= -180 && parsed <= 180;
                            },
                            errorString: 'Skriv in en giltig longitud',
                        },
                        {
                            func: (value, formValues) => {
                                if (value === '' && formValues.latitude) {
                                    return false;
                                }

                                return true;
                            },
                            errorString: 'Skriv in en longitud',
                        },
                    ],
                },
                latitude: {
                    nullable: false,
                    tests: [
                        {
                            func: (value) => {
                                if (value === '') {
                                    return true;
                                }

                                const parsed = parseFloat(value);
                                return !isNaN(value) && parsed >= -180 && parsed <= 180;
                            },
                            errorString: 'Skriv in en giltig latitud',
                        },
                        {
                            func: (value, formValues) => {
                                if (value === '' && formValues.longitude) {
                                    return false;
                                }

                                return true;
                            },
                            errorString: 'Skriv in en latitud',
                        },
                    ],
                },
                bookableMessage: {
                    nullable: false,
                    tests: [
                        {
                            func: (value, formValues) => {
                                if (!formValues.bookable && (value === '' || value === null)) {
                                    return false;
                                }
                                return true;
                            },
                            errorString: 'Välj en anledning',
                        },
                    ],
                },
            },
        },
    );

    function resetForm() {
        reset();
        mapRef?.current?.reCenter();
    }

    useEffect(() => {
        mapRef?.current?.reCenter();
    }, [course]);

    return (
        <form onSubmit={handleSubmit}>
            <div className="flex items-center mb-6 justify-between">
                <Header size="medium">{course.courseName!}</Header>
                <div className="flex gap-2">
                    <Button type="submit" disabled={!isDirty}>
                        Spara
                    </Button>
                    <Button variant="warning" disabled={!isDirty} onClick={() => resetForm()}>
                        Ångra
                    </Button>
                </div>
            </div>
            <hr className="my-6" />
            <div className="flex gap-6 items-center">
                <div className="w-full">
                    <h3 className="text-base font-medium">Ordning</h3>
                    <p className="text-gray-500 font-normal text-sm">
                        Välj vilken ordning banan ska ha i MGBoknings sökresultatlista
                    </p>
                </div>
                <div className="w-full">
                    <Input
                        type="number"
                        placeholder="Skriv in ett nummer"
                        onChange={(val) => handleValueChange('sorting', parseInt(val))}
                        value={formValues.sorting?.toString() || undefined}
                        name={`${course.id}_sorting`}
                    />
                </div>
            </div>
            <hr className="my-6" />
            <div className="flex gap-6 items-center">
                <div className="w-full">
                    <h3 className="text-base font-medium">Visning i app</h3>
                    <p className="text-gray-500 font-normal text-sm">Välj om banan ska visas i MGBokning</p>
                </div>
                <div className="w-full">
                    <Switch
                        checked={formValues.showInApp || false}
                        label={formValues.showInApp ? 'Visas' : 'Visas ej'}
                        id={`${course.id}_display_in_app`}
                        onChange={() => handleValueChange('showInApp', !formValues.showInApp)}
                    />
                </div>
            </div>
            <hr className="my-6" />
            <div className="flex gap-6 items-center">
                <div className="w-full">
                    <h3 className="text-base font-medium">Bokningsbar</h3>
                    <p className="text-gray-500 font-normal text-sm">Välj om banan ska gå att boka i MGBokning</p>
                </div>
                <div className="w-full justify-end flex flex-col gap-2">
                    <Switch
                        checked={formValues.bookable || false}
                        label={formValues.bookable ? 'Bokningsbar' : 'Ej bokningsbar'}
                        id={`${course.id}_bookable`}
                        onChange={(val) => {
                            if (formValues.bookable) handleValueChange('bookableMessage', '');

                            handleValueChange('bookable', !formValues.bookable);
                        }}
                    />
                    <div className="relative">
                        <Select
                            placeholder="Välj anledning"
                            defaultValue={formValues.bookableMessage || ''}
                            disabled={formValues.bookable}
                            name={`${course.id}_bookableMessage`}
                            onChange={(val) => {
                                handleValueChange('bookableMessage', val);
                            }}
                        >
                            <option value="" disabled>
                                Välj anledning
                            </option>
                            <option value="Bollränna">Bollränna</option>
                            <option value="Annan">Annan</option>
                        </Select>
                        {errors['bookableMessage'] && (
                            <p className="form-error absolute left-0">{errors['bookableMessage']}</p>
                        )}
                    </div>
                </div>
            </div>
            <hr className="my-6" />
            <div className="flex items-center">
                <h3 className="text-base font-medium ">Banans position</h3>
            </div>
            <p className="text-sm text-gray-500 mb-6">
                Skriv i nya kordinater eller klicka på kartan för att välja ny position
            </p>
            <div className="flex items-end mb-6 gap-4">
                <div className="relative">
                    <Input
                        label="Longitud"
                        placeholder={formValues.longitude?.toString()}
                        value={formValues.longitude || undefined}
                        onChange={(val) => handleValueChange('longitude', val.replace(',', '.'))}
                        name="longitude"
                    />
                    {errors['longitude'] && <p className="form-error absolute left-0">{errors['longitude']}</p>}
                </div>
                <div className="relative">
                    <Input
                        label="Latitud"
                        placeholder={formValues.latitude?.toString()}
                        value={formValues.latitude || undefined}
                        onChange={(val) => handleValueChange('latitude', val.replace(',', '.'))}
                        name="latitude"
                    />
                    {errors['latitude'] && <p className="form-error absolute left-0">{errors['latitude']}</p>}
                </div>
            </div>
            <Map
                ref={mapRef}
                longitude={formValues.longitude ? parseFloat(formValues.longitude) : null}
                latitude={formValues.latitude ? parseFloat(formValues.latitude) : null}
                center={
                    course.latitude && course.longitude
                        ? { lat: parseFloat(course.latitude), lng: parseFloat(course.longitude) }
                        : undefined
                }
                label={course.courseName!}
                addNewMarker={true}
                onChangePosition={(latLng) => {
                    handleValueChange('latitude', latLng.lat);
                    handleValueChange('longitude', latLng.lng);
                }}
            />
        </form>
    );
}
