import { AuthProvider } from 'context/AuthContext';
import { ModalProvider } from 'context/ModalContext';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import sv from 'date-fns/locale/sv';
import AppEntry from './AppEntry';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import LayoutProvider from 'context/LayoutContext';
import ErrorBoundry from './ErrorBoundry';

export const queryClient = new QueryClient();

registerLocale('sv', sv);
setDefaultLocale('sv');

export default function App() {
    return (
        <QueryClientProvider client={queryClient}>
            <AuthProvider>
                <ModalProvider>
                    <LayoutProvider>
                        <ErrorBoundry>
                            <AppEntry />
                        </ErrorBoundry>
                    </LayoutProvider>
                </ModalProvider>
            </AuthProvider>
        </QueryClientProvider>
    );
}
