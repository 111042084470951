import { useState, useEffect } from 'react';
import Button from 'components/Button';
import Table from 'components/Table';

import { Tabs, Tab, TabList, TabView, TabViews } from 'components/Tabs';
import { FaInfoCircle } from 'react-icons/fa';
import Modal from 'components/Modal';
import Header from 'components/Header';
import Dropdown from 'components/Dropdown';
import ApiClient from 'api/ApiClient';
import { FaEllipsisH } from 'react-icons/fa';
import { GsPushNotificationConfigStatus } from 'api/openapi-club';
import { Link } from 'react-router-dom';
import { queryClient } from 'App';
import { useQuery, useMutation } from '@tanstack/react-query';
import { useAuthenticatedUser } from 'context/AuthenticatedUserContext';
import { useModal } from 'context/ModalContext';

function InfoModal() {
    return (
        <Modal title="Skapa pushnotiser">
            <p className="text-sm text-gray-700">
                Här kan du skapa mobilnotifikationer till spelare som har eller har haft en bokning på någon av klubbens
                banor tidigast sex månader tillbaka i tiden och senast sex månader framåt.
            </p>
            <br />

            <p className="text-sm text-gray-700">
                Under "Automatiska Utskick" kan du även se vilka notifikationer som automatiskt skickas från
                GolferSweden och SGF.
            </p>
        </Modal>
    );
}

function NotificationStatus({ status }: { status: GsPushNotificationConfigStatus }) {
    switch (status) {
        case GsPushNotificationConfigStatus.RECURRING:
            return (
                <strong className="rounded bg-green-100 px-3 py-1.5 text-xs font-medium text-green-700">
                    Repeterande
                </strong>
            );
        case GsPushNotificationConfigStatus.PENDING:
            return (
                <strong className="rounded bg-amber-100 px-3 py-1.5 text-xs font-medium text-amber-700">Väntar</strong>
            );
        case GsPushNotificationConfigStatus.SENT:
            return (
                <strong className="rounded bg-green-100 px-3 py-1.5 text-xs font-medium text-green-700">Skickad</strong>
            );
        case GsPushNotificationConfigStatus.DISABLED:
            return (
                <strong className="rounded bg-red-100 px-3 py-1.5 text-xs font-medium text-red-700">Inaktiverad</strong>
            );
        default:
            return <strong className="rounded bg-amber-100 px-3 py-1.5 text-xs font-medium text-amber-700"></strong>;
    }
}

export default function ListPushPage(): JSX.Element {
    const { roles } = useAuthenticatedUser();
    const [page, setPage] = useState(0);
    const [error, setError] = useState<unknown>();
    const { showModal } = useModal();

    const resultsPerPage = 10;

    const { data: automatedNotificationConfigs } = useQuery(
        ['auto-notifications'],
        () => ApiClient.gsNotification.getClubPortalNotificationsAutomated(),
        {
            onError: (error) => {
                setError(error);
            },
        },
    );

    const fetchNotifications = (page: number = 0) =>
        ApiClient.gsNotification.getClubPortalNotificationsConfigs(resultsPerPage, page * resultsPerPage);

    const { data: notificationConfigs } = useQuery({
        queryKey: ['notifications', page],
        queryFn: () => fetchNotifications(page),
        keepPreviousData: true,
        enabled: roles.includes('club-admin'),
        onError: (error) => {
            setError(error);
        },
    });

    const deleteMutation = useMutation(
        (id: number) => {
            return ApiClient.gsNotification.deleteClubPortalNotificationsConfig(id);
        },
        {
            onError: (error) => {
                setError(error);
            },
            onSuccess: () => {
                queryClient.invalidateQueries(['notifications', page], { exact: true });
            },
        },
    );

    useEffect(() => {
        if (error) {
            throw error;
        }
    }, [error]);

    function onDelete(id: number) {
        setTimeout(() => {
            window.confirm('Är du säker på att du vill ta bort notifikationen?') && deleteMutation.mutate(id);
        }, 50);
    }

    return (
        <main className="mb-8">
            <div className="py-6">
                <div className="flex items-center justify-between">
                    <div className="flex items-center gap-4">
                        <Header className="lh">Pushnotiser</Header>
                        <Button variant="text" onClick={() => showModal(<InfoModal />)}>
                            <FaInfoCircle size={18} />
                        </Button>
                    </div>
                    <Button variant="primary" component={Link} to="/push/create">
                        Skapa ny notis
                    </Button>
                </div>
            </div>

            <Tabs>
                <TabList>
                    <Tab>Klubbnotiser</Tab>
                    <Tab>Automatiska utskick</Tab>
                </TabList>
                <TabViews>
                    <TabView>
                        {notificationConfigs && (
                            <>
                                <Table
                                    hideCols={['id', 'type', 'title', 'segment']}
                                    headers={{
                                        id: 'ID',
                                        status: 'Status',
                                        when: 'Skickas/Skickades',
                                        title: 'Titel',
                                        message: 'Meddelande',
                                        type: 'Typ',
                                        actions: '',
                                        segment: '',
                                    }}
                                    items={notificationConfigs.map((notificationConfig) => ({
                                        ...notificationConfig,
                                        when: (
                                            <p className="whitespace-nowrap">
                                                {notificationConfig.when &&
                                                    `${new Date(notificationConfig.when).toLocaleString('sv-SE', {
                                                        dateStyle: 'medium',
                                                    })} kl. ${new Date(notificationConfig.when).toLocaleTimeString(
                                                        'sv-SE',
                                                        {
                                                            timeStyle: 'short',
                                                        },
                                                    )}`}
                                            </p>
                                        ),

                                        message: (
                                            <>
                                                <p className="pb-2 font-medium text-gray-900">
                                                    {notificationConfig.title}
                                                </p>
                                                <p className=" text-gray-700 table__message-text">
                                                    {notificationConfig.message}
                                                </p>
                                            </>
                                        ),

                                        status: <NotificationStatus status={notificationConfig.status} />,
                                        actions: (
                                            <div className="flex items-end justify-end">
                                                <Dropdown
                                                    options={[
                                                        {
                                                            label: 'Ta bort',
                                                            onClick: () => onDelete(notificationConfig.id),
                                                        },
                                                    ]}
                                                    Control={
                                                        <Button variant="outlined" style={{ padding: '0 16px' }}>
                                                            <FaEllipsisH />
                                                        </Button>
                                                    }
                                                />
                                            </div>
                                        ),
                                    }))}
                                />
                                <div className="flex gap-2 items-center justify-end my-4">
                                    <p className="text-gray-700">Sida {page + 1}</p>
                                    <Button
                                        variant="outlined"
                                        disabled={page === 0}
                                        onClick={() => setPage((currentPage) => currentPage - 1)}
                                    >
                                        Föregående
                                    </Button>
                                    <Button
                                        variant="outlined"
                                        disabled={notificationConfigs.length < resultsPerPage}
                                        onClick={() => setPage((currentPage) => currentPage + 1)}
                                    >
                                        Nästa
                                    </Button>
                                </div>
                            </>
                        )}
                    </TabView>
                    <TabView>
                        {automatedNotificationConfigs && (
                            <Table
                                cellSpacing
                                hideCols={['id', 'status', 'when', 'type']}
                                headers={{
                                    id: 'ID',
                                    status: '',
                                    type: '',
                                    title: 'Typ',
                                    message: 'Meddelande',
                                    when: '',
                                    segment: '',
                                }}
                                items={automatedNotificationConfigs}
                            />
                        )}
                    </TabView>
                </TabViews>
            </Tabs>
        </main>
    );
}
