import React, { useContext, useEffect } from 'react';

import ApiClient from '../api/ApiClient';
import { GsMeResponse } from '../api/openapi-club';

import AuthService from '../services/AuthService';

interface IAuthContext {
    authState: AuthState;
}
interface AuthProviderProps {
    children: JSX.Element | JSX.Element[];
    value?: IAuthContext;
}
interface LoadingState {
    status: AuthStatus.LOADING;
}
interface SignedInState {
    status: AuthStatus.SIGNED_IN;
    roles: string[];
    user: GsMeResponse;
}
interface SignedOutState {
    status: AuthStatus.SIGNED_OUT;
}

export enum AuthStatus {
    LOADING,
    SIGNED_IN,
    SIGNED_OUT,
}

const AuthContext = React.createContext<IAuthContext>(undefined!);

export function useAuth() {
    return useContext(AuthContext);
}

type AuthState = LoadingState | SignedInState | SignedOutState;

export function AuthProvider({ children }: AuthProviderProps) {
    const [authState, setAuthState] = React.useState<AuthState>({
        status: AuthStatus.LOADING,
    });

    useEffect(() => {
        let onSignInListner = AuthService.addListener(AuthService.EventType.onSignIn, async () => {
            const userAccount = await getUser();
            const roles = await AuthService.getUserRoles();

            setAuthState({ status: AuthStatus.SIGNED_IN, user: userAccount, roles });
        });

        let onSignOutListner = AuthService.addListener(AuthService.EventType.onSignOut, async () =>
            setAuthState({ status: AuthStatus.SIGNED_OUT }),
        );

        async function initialSetUser() {
            if (await AuthService.hasCurrentSession()) {
                try {
                    const userAccount = await getUser();
                    const roles = await AuthService.getUserRoles();
                    setAuthState({ status: AuthStatus.SIGNED_IN, user: userAccount, roles });
                } catch (e) {
                    setAuthState({ status: AuthStatus.SIGNED_OUT });
                }
            } else {
                setAuthState({ status: AuthStatus.SIGNED_OUT });
            }
        }
        initialSetUser();

        return function cleanUp() {
            AuthService.removeListner(onSignOutListner);
            AuthService.removeListner(onSignInListner);
        };
    }, []);

    async function getUser() {
        return ApiClient.gsAccount.getClubPortalAccount();
    }

    const value = {
        authState,
    };

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
