import Modal from 'components/Modal';

export default function MaxReducedPricesPerBlockHelpModal() {
    return (
        <Modal title="Max antal reducerade tider">
            <p className="text-sm text-gray-700">
                Här kan du ange hur många priser som ska kunna vara reducerade samtidigt per dag. Antalet måste anges i
                en multipel av fyra så att det kan finnas en tid på morgonen, förmiddagen, eftermiddagen och kvällen.
            </p>
        </Modal>
    );
}
