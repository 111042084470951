import React from 'react';
import './Tabs.css';
import type { TabProps } from './Tab';
export interface TabListProps {
    children: React.ReactElement<TabProps>[];
    /** prop is injected by Tabs */
    onClick?: (index: number) => void;
    /** prop is injected by Tabs */
    selectedIndex?: number;
}

export default function TabList({ children, onClick, selectedIndex }: TabListProps) {
    const childrenWithProps = React.Children.map(children, (child, index) => {
        if (React.isValidElement(child)) {
            return React.cloneElement(child, {
                selected: selectedIndex === index,
                onClick: () => onClick && onClick(index),
            });
        }
        return child;
    });

    return <div className="tabs">{childrenWithProps}</div>;
}
