import './form.css';

import { Link } from 'react-router-dom';
import Button from 'components/Button';

import Header from 'components/Header';

import CreatePushForm from 'forms/CreatePushForm';

import { FaChevronLeft } from 'react-icons/fa';
import ApiClient from 'api/ApiClient';
import { GsCreateClubPushNotificationConfigRequest, GsPushNotificationConfigResponse } from '../api/openapi-club';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from '@tanstack/react-query';
import { queryClient } from 'App';

export default function CreatePushPage() {
    const navigate = useNavigate();
    const { data: courses } = useQuery(['courses'], () => ApiClient.gsClub.getClubPortalClubCourses());
    const mutation = useMutation(
        (payload: GsCreateClubPushNotificationConfigRequest) => {
            return ApiClient.gsNotification.postClubPortalNotificationsConfig(payload);
        },
        {
            onSuccess: (data) => {
                queryClient.setQueryData(
                    ['notifications'],
                    (oldData: GsPushNotificationConfigResponse[] | undefined) => {
                        return oldData ? [...oldData, data] : [data];
                    },
                );

                navigate('/push');
            },
        },
    );

    if (!courses) {
        return null;
    }

    return (
        <main>
            <div className="py-6 gap-6 flex">
                <Button variant="text" component={Link} to="/push">
                    <FaChevronLeft size={16} />
                    Tillbaka
                </Button>
                <Header className="lh">Skapa ny notis</Header>
            </div>

            {courses ? (
                <CreatePushForm onSubmit={mutation.mutate} courses={courses} isLoading={mutation.isLoading} />
            ) : (
                <div>Laddar...</div>
            )}
        </main>
    );
}
