import { objectKeys } from 'types/helpers';

import './Table.css';

type TableHeaders<T> = Record<keyof T, string>;

interface TableProps<T> {
    items: T[];
    headers: TableHeaders<T>;
    hideCols?: string[];
    cellSpacing?: boolean;
}

export default function Table<T>({ items, headers, hideCols = [], cellSpacing = false }: TableProps<T>) {
    const keys = objectKeys(headers).filter((key) => !hideCols.includes(key.toString()));

    function renderRow(item: T, i: number) {
        return (
            <tr key={i}>
                {keys.map((itemProperty, i) => (
                    <td className={`px-4  text-gray-700 ${cellSpacing ? 'py-4' : 'py-2'}`} key={i}>
                        <>{item[itemProperty]}</>
                    </td>
                ))}
            </tr>
        );
    }
    return (
        <div className="rounded-lg border border-gray-200">
            <table className="min-w-full divide-y divide-gray-200 text-sm">
                <thead className="bg-gray-100">
                    <tr>
                        {keys.map((headerValue, i) => (
                            <th className="whitespace-nowrap px-4 py-2 text-left font-medium text-gray-900" key={i}>
                                {headers[headerValue]}
                            </th>
                        ))}
                    </tr>
                </thead>

                <tbody className="divide-y divide-gray-200 bg-white">{items.map(renderRow)}</tbody>
            </table>
        </div>
    );
}
