import { useState } from 'react';
import Input from 'components/Input/Input';
import { useLocation } from 'react-router-dom';
import Button from 'components/Button';
import useForm from 'hooks/useForm';
import AuthService from 'services/AuthService';

interface ILoginForm {
    username: string;
    code: string;
    password: string;
}
export default function SetNewPasswordPage() {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string | undefined>(undefined);
    const location = useLocation();
    const { email } = location.state;

    const { handleValueChange, handleSubmit, formValues } = useForm<ILoginForm>(
        {
            username: email,
            code: '',
            password: '',
        },
        {
            onSubmit,
        },
    );

    async function onSubmit() {
        setIsLoading(true);

        try {
            await AuthService.forgotPasswordSubmit(formValues);
            await AuthService.signIn(formValues.username, formValues.password);
        } catch (e) {
            setIsLoading(false);
            if (e instanceof Error) {
                setError(e.message);
            }
        }
    }

    return (
        <main className="flex flex-col items-center justify-center p-8 h-screen">
            <h1 className="text-3xl font-bold mb-2">Golfers Portal</h1>

            <div className="rounded-3xl max-w-lg w-full bg-white p-8">
                <h2 className="mb-4 font-medium">Välj nytt lösenord</h2>
                <p className="text-gray-700 text-sm mb-2">
                    En kod har skickats till <b>{email}</b>.
                </p>
                <p className="text-gray-700 text-sm mb-4">Ange koden och ditt nya lösenord nedan.</p>
                <form onSubmit={handleSubmit} className="flex flex-col">
                    <div className=" flex flex-col gap-2">
                        <Input
                            type="number"
                            label="Verifikationskod"
                            placeholder="Skriv in din verifikationskod"
                            name="code"
                            onChange={(text) => {
                                handleValueChange('code', text);
                                setError(undefined);
                            }}
                        />
                        <Input
                            type="password"
                            label="Nytt lösenord"
                            placeholder="Skriv in ditt nya lösenord"
                            name="code"
                            onChange={(text) => {
                                handleValueChange('password', text);
                                setError(undefined);
                            }}
                        />
                    </div>
                    <p className="form-error h-6 text-right">{error}</p>

                    <div className="flex justify-between items-center">
                        <Button name="submit" type="submit" large isLoading={isLoading}>
                            Skicka
                        </Button>
                    </div>
                </form>
            </div>
        </main>
    );
}
