import './Tabs.css';
import classnames from 'classnames';

export interface TabProps {
    children: React.ReactNode;
    /** prop is injected by TabList */
    selected?: boolean;
    /** prop is injected by TabList */
    onClick?: any;
}

export default function Tab({ children, onClick, selected = false }: TabProps) {
    return (
        <div
            onClick={() => {
                onClick();
            }}
            className={classnames(['tabs__tab', selected && 'tabs__tab--selected'])}
        >
            {children}
        </div>
    );
}
