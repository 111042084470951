import { MapContainer, TileLayer, Marker, Tooltip, useMapEvents, Circle, useMap } from 'react-leaflet';

import { useImperativeHandle, forwardRef, useRef, useEffect } from 'react';
import { LatLng } from 'leaflet';

interface INewMarkerComponent {
    onMapPress: (latlng: LatLng) => void;
}

interface IRecenterHandle {
    reCenter: () => void;
}
interface IRecenterComponentProps {
    lat: number;
    lng: number;
    zoom: number;
}

const RecenterComponent = forwardRef<IRecenterHandle, IRecenterComponentProps>(({ lat, lng, zoom = 14 }, ref) => {
    const map = useMap();
    useImperativeHandle(ref, () => ({
        reCenter() {
            map.flyTo([lat, lng], zoom);
        },
    }));

    useEffect(() => {
        map.flyTo([lat, lng], zoom);
    }, [lat, lng, map, zoom]);

    return null;
});

function NewMarkerComponent({ onMapPress }: INewMarkerComponent) {
    useMapEvents({
        click: (e) => {
            onMapPress(e.latlng);
        },
    });
    return null;
}

export interface IMapHandle {
    reCenter: () => void;
}
interface IMapProps {
    longitude?: number | null;
    latitude?: number | null;
    center?: {
        lat: number;
        lng: number;
    };
    addNewMarker?: boolean;
    onChangePosition: (latlng: LatLng) => void;
    label?: string;
}

const defaultCenter = {
    lat: 62.416997,
    lng: 15.830593,
};

const Map = forwardRef<IMapHandle, IMapProps>(
    ({ longitude, latitude, center, label, addNewMarker, onChangePosition }, ref) => {
        const reCenterComponentRef = useRef<IRecenterHandle>(null);
        useImperativeHandle(ref, () => ({
            reCenter() {
                _reCenter();
            },
        }));

        function _reCenter() {
            reCenterComponentRef.current?.reCenter();
        }

        function handleChangePosition(latlng: LatLng) {
            // Recalculate longitude if clicking at a wrapped point on the map
            let lng = latlng.lng;

            if (lng > 180) {
                lng = (lng % 180) - 180;
            } else if (lng < -180) {
                lng = (lng % -180) + 180;
            }

            const newLatlng = new LatLng(latlng.lat, lng);

            onChangePosition(newLatlng);
        }

        return (
            <div style={{ height: '400px', width: '100%' }}>
                <MapContainer
                    center={center || defaultCenter}
                    worldCopyJump={true} // Copy marker to the other side of the map
                    zoom={center ? 14 : 4}
                    style={{ display: 'block', height: '100%', minHeight: ' 100%', width: '100%' }}
                >
                    {longitude && latitude && (
                        <>
                            <Circle center={{ lat: latitude, lng: longitude }} radius={1000} />

                            <Marker position={{ lat: latitude, lng: longitude }}>
                                <Tooltip permanent>{label}</Tooltip>
                            </Marker>
                        </>
                    )}
                    <RecenterComponent
                        {...(center || defaultCenter)}
                        zoom={center ? 14 : 4}
                        ref={reCenterComponentRef}
                    />

                    {addNewMarker && <NewMarkerComponent onMapPress={(latLng) => handleChangePosition(latLng)} />}

                    <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                </MapContainer>
            </div>
        );
    },
);

export default Map;
