import { S3Client, GetObjectCommand } from '@aws-sdk/client-s3';
import { getSignedUrl as S3GetSignedUrl } from '@aws-sdk/s3-request-presigner';

import { Auth } from 'aws-amplify';

async function getSignedUrl(key: string, bucket = process.env.REACT_APP_S3_BUCKET_NAME) {
    const credentials = await Auth.currentCredentials();

    const s3 = new S3Client({
        region: process.env.REACT_APP_REGION,
        credentials: Auth.essentialCredentials(credentials),
    });

    const params = {
        ContentType: 'image/jpeg',
        Bucket: bucket,
        Key: key.substring(1),
    };

    try {
        const command = new GetObjectCommand(params);
        const url = await S3GetSignedUrl(s3, command, { expiresIn: 300 });
        return url;
    } catch (e) {
        throw e;
    }
}

async function getLinkToFile(key: string) {
    try {
        const url = await getSignedUrl(key, `${process.env.REACT_APP_S3_BUCKET_NAME}`);
        if (url) {
            window.open(url, '_blank');
        }
    } catch (e) {
        throw e;
    }
}

const S3Service = {
    getSignedUrl,
    getLinkToFile,
};

export default S3Service;
