import { useState } from 'react';
import Input from 'components/Input/Input';
import { Link, useNavigate } from 'react-router-dom';
import Button from 'components/Button';
import useForm from 'hooks/useForm';
import AuthService from 'services/AuthService';

interface ILoginForm {
    email: string;
}
export default function RequestNewPasswordPage() {
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const [error, setError] = useState<string | undefined>(undefined);

    const { handleValueChange, handleSubmit, formValues } = useForm<ILoginForm>(
        {
            email: '',
        },
        {
            onSubmit,
        },
    );

    async function onSubmit() {
        setIsLoading(true);

        try {
            await AuthService.forgotPassword(formValues.email);
            navigate('/set-new-password', { replace: true, state: { email: formValues.email } });
        } catch (e) {
            setIsLoading(false);
            if (e instanceof Error) {
                setError(e.message);
            }
        }
    }

    return (
        <main className="flex flex-col items-center justify-center p-8 h-screen">
            <h1 className="text-3xl font-bold mb-2">Golfers Portal</h1>

            <div className="rounded-3xl max-w-lg w-full bg-white p-8">
                <h2 className="mb-4 font-medium">Glömt lösenord</h2>
                <p
                    style={{ paddingBottom: '3px' }}
                    className="text-gray-5
                700 text-sm mb-4"
                >
                    Skriv in din e-postadress så skickar vi ett mail med instruktioner om hur du återställer ditt
                    lösenord.
                </p>
                <form onSubmit={handleSubmit} className="flex flex-col">
                    <Input
                        type="email"
                        placeholder="Skriv in din e-postadress"
                        name="email"
                        onChange={(text) => {
                            handleValueChange('email', text);
                            setError(undefined);
                        }}
                    />
                    <p className="form-error h-6 text-right">{error}</p>

                    <div className="flex justify-between items-center gap-4">
                        <Button name="submit" type="submit" large isLoading={isLoading}>
                            Skicka
                        </Button>
                        <Link to="/login" className="text-primary text-sm">
                            Tillbaka till logga in
                        </Link>
                    </div>
                </form>
            </div>
        </main>
    );
}
