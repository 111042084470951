import React from 'react';
import './Tabs.css';
import type { TabViewProps } from './TabView';

export interface TabViewsProps {
    children: React.ReactElement<TabViewProps>[];
    /** prop is injected by Tabs */
    selectedIndex?: number;
}

export default function TabViews({ children, selectedIndex }: TabViewsProps) {
    const childrenWithProps = React.Children.map(children, (child, index) => {
        if (React.isValidElement(child)) {
            return React.cloneElement(child, {
                selected: selectedIndex === index,
            });
        }
        return child;
    });

    return <div className="tabs__views">{childrenWithProps}</div>;
}
