import './Input.css';
import classnames from 'classnames';

interface InputProps {
    value?: string | undefined;
    onChange?: (value: string) => void;
    onBlur?: (value: string) => void;
    placeholder?: string;
    disabled?: boolean;
    name: string;
    type?:
        | 'date'
        | 'datetime-local'
        | 'email'
        | 'number'
        | 'password'
        | 'month'
        | 'search'
        | 'text'
        | 'tel'
        | 'time'
        | 'url'
        | 'week';
    label?: string | JSX.Element;
    min?: number;
    max?: number;
}

export default function Input({
    value,
    onChange = () => {},
    onBlur = () => {},
    disabled = false,
    placeholder,
    type = 'text',
    name,
    label,
    min,
    max,
}: InputProps) {
    const labelComponent = typeof label === 'string' ? <label className="input__label">{label}</label> : label;
    return (
        <div className="input">
            {label && labelComponent}

            <input
                className={classnames('input__control', disabled && 'input__control--disabled')}
                type={type}
                disabled={disabled}
                name={name}
                placeholder={placeholder}
                value={value}
                onBlur={(e) => onBlur(e.currentTarget.value)}
                onChange={(e) => onChange(e.currentTarget.value)}
                min={min}
                max={max}
            />
        </div>
    );
}
