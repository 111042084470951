import { useQuery, useMutation } from '@tanstack/react-query';
import Header from 'components/Header';
import { queryClient } from 'App';
import ApiClient from 'api/ApiClient';
import { GsUpdateCourseRequest } from 'api/openapi-club';
import CourseSettingsForm from 'forms/CourseSettingsForm';
import { useEffect, useState } from 'react';

export default function CoursesPage() {
    const [error, setError] = useState<unknown>();
    const { data: courses, isLoading } = useQuery(['courses'], () => ApiClient.gsClub.getClubPortalClubCourses(), {
        onError: (error) => {
            setError(error);
        },
    });

    const { mutate } = useMutation(
        ({ id, payload }: { id: number; payload: GsUpdateCourseRequest }) => {
            return ApiClient.gsCourse.putClubPortalCourses(id, payload);
        },
        {
            onError: (error) => {
                setError(error);
            },
            onSuccess: () => {
                queryClient.invalidateQueries(['courses'], { exact: true });
            },
        },
    );

    useEffect(() => {
        if (error) {
            throw error;
        }
    }, [error]);

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (!courses) {
        return <div>No courses</div>;
    }

    return (
        <main>
            <div className="max-w-7xl py-6">
                <Header className="lh">Banor</Header>
            </div>
            {courses
                ?.sort((a, b) => ((a.sorting || 99) > (b.sorting || 99) ? 1 : -1))
                .map((course) => (
                    <div key={course.id} className="shadow bg-white rounded p-5 items-center mb-8">
                        <CourseSettingsForm course={course} onSubmit={mutate} />
                    </div>
                ))}
        </main>
    );
}
