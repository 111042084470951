import { Route, Routes, Navigate } from 'react-router-dom';
import LoginPage from 'pages/authentication/LoginPage';
import RequestNewPasswordPage from 'pages/authentication/RequestNewPasswordPage';
import SetNewPasswordPage from 'pages/authentication/SetNewPasswordPage';

import CreatePushPage from 'pages/CreateNotificationPage';
import ListPushPage from 'pages/ListPushPage';
import DynamicPricingPage from 'pages/DynamicPricingPage';

import StatisticsPage from 'pages/StatisticsPage';
import ClubSettingsPage from 'pages/ClubSettingsPage';
import WelcomePage from 'pages/WelcomePage';
import CoursesPage from 'pages/CoursesPage';
import RequireAuth from 'layout/RequireAuth';
import { useAuth, AuthStatus } from 'context/AuthContext';

export default function App() {
    const { authState } = useAuth();
    return (
        <Routes>
            <Route
                path="/login"
                element={authState.status === AuthStatus.SIGNED_IN ? <Navigate to="/" replace /> : <LoginPage />}
            />
            <Route
                path="/forgot-password"
                element={
                    authState.status === AuthStatus.SIGNED_IN ? <Navigate to="/" replace /> : <RequestNewPasswordPage />
                }
            />
            <Route
                path="/set-new-password"
                element={
                    authState.status === AuthStatus.SIGNED_IN ? <Navigate to="/" replace /> : <SetNewPasswordPage />
                }
            />
            <Route element={<RequireAuth />}>
                <Route path="/" element={<WelcomePage />} />
                <Route path="/statistics" element={<StatisticsPage />} />
            </Route>

            <Route element={<RequireAuth requireRole="club-admin" />}>
                <Route path="/push" element={<ListPushPage />} />
                <Route path="/club-settings" element={<ClubSettingsPage />} />
                <Route path="/courses" element={<CoursesPage />} />
                <Route path="/push/create" element={<CreatePushPage />} />
                <Route path="/dynamic-pricing" element={<DynamicPricingPage />} />
            </Route>
        </Routes>
    );
}
