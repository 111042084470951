import './TextArea.css';
import { TextareaHTMLAttributes } from 'react';
interface TextAreaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
    rows?: number;

    label?: string;
    /*
    placeholder?: string;
    name?: string;
    onChange?: (text: string) => void;
    */
}

export default function TextArea({ label, rows = 6, ...rest }: TextAreaProps) {
    return (
        <label className="textarea__label">
            {label}

            <textarea className="textarea" {...rest} rows={rows}></textarea>
        </label>
    );
}
