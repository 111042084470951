import './Button.css';

import classnames from 'classnames';
import Spinner from 'components/Spinner';
interface ButtonProps {
    children: React.ReactNode;
    large?: boolean;
    name?: string;
    disabled?: boolean;
    isLoading?: boolean;
    type?: 'button' | 'submit' | 'reset' | undefined;
    onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void | (() => void) | Promise<void>;
    variant?: 'primary' | 'text' | 'outlined' | 'warning';
    component?: React.ElementType;
    to?: string;
    style?: React.CSSProperties;
}
export default function Button({
    children,
    onClick,
    name,
    type = 'button',
    large = false,
    variant = 'primary',
    component = 'button',
    disabled = false,
    isLoading = false,
    to,
    style,
}: ButtonProps) {
    const Component = component;
    return (
        <Component
            type={type}
            name={name}
            disabled={disabled || isLoading}
            to={to}
            style={style}
            onClick={onClick}
            className={classnames('button', `button--${variant}`, large && 'button--large')}
        >
            {isLoading ? <Spinner size={12} /> : children}
        </Component>
    );
}
