/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GsGlobalMessageResponse } from '../models/GsGlobalMessageResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class GsGlobalMessagesService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns GsGlobalMessageResponse Success
     * @throws ApiError
     */
    public getClubPortalGlobalMessage(): CancelablePromise<Array<GsGlobalMessageResponse>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/club-portal/global-message',
            errors: {
                400: `Bad Request`,
                500: `Server Error`,
            },
        });
    }

}
