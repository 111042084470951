import './Switch.css';
interface ISwitcProps {
    checked: boolean;
    onChange: (checked: boolean) => void;
    label?: string;
    disabled?: boolean;
    id: string;
}

export default function Switch({ checked, onChange, label, disabled, id }: ISwitcProps) {
    return (
        <>
            <div className="switch">
                <label htmlFor={id} className="flex items-center cursor-pointer">
                    <div className="relative">
                        <input
                            type="checkbox"
                            id={id}
                            className="sr-only switch__input"
                            checked={checked}
                            onChange={(e) => onChange(e.currentTarget.checked)}
                        />
                        <div className="block bg-gray-600 w-14 h-8 rounded-full switch__backdrop"></div>
                        <div className="switch__dot absolute left-1 top-1 bg-white w-6 h-6 rounded-full transition"></div>
                    </div>
                    {label && <div className="ml-3 text-gray-700 font-medium">{label}</div>}
                </label>
            </div>
        </>
    );
}
