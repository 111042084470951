import Input from 'components/Input/Input';
import Button from 'components/Button';
import useForm from 'hooks/useForm';

interface IForm {
    password: string;
}

export default function ForceNewPasswordForm({
    onSubmit,
    isLoading,
    error,
    setError,
}: {
    onSubmit: (password: string) => void;
    isLoading: boolean;
    error?: string;
    setError: (error?: string) => void;
}) {
    const { handleValueChange, handleSubmit, formValues } = useForm<IForm>(
        {
            password: '',
        },
        {
            onSubmit: async () => {
                onSubmit(formValues.password);
            },
        },
    );

    return (
        <>
            <p className="text-gray-700 text-sm mb-2">Fyll i ditt nya lösenord nedan.</p>
            <form onSubmit={handleSubmit} className="flex flex-col">
                <Input
                    type="password"
                    label="Nytt lösenord"
                    placeholder="Skriv in ditt nya lösenord"
                    name="code"
                    onChange={(text) => {
                        handleValueChange('password', text);
                        setError(undefined);
                    }}
                />
                <p className="form-error h-6 text-right">{error}</p>

                <div className="flex justify-between items-center gap-4">
                    <Button name="submit" type="submit" large isLoading={isLoading}>
                        Skicka
                    </Button>
                </div>
            </form>
        </>
    );
}
