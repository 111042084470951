import { Component, ReactNode } from 'react';
import Modal from 'components/Modal';
import { withModal } from 'context/ModalContext';
interface Props {
    children: ReactNode;
    showModal: (component: ReactNode) => void;
    hideModal: () => void;
}

interface State {
    hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
    public state: State = {
        hasError: false,
    };

    public static getDerivedStateFromError(_: Error): State {
        return { hasError: true };
    }

    public componentDidCatch(error: any /*, errorInfo: ErrorInfo*/) {
        this.props.showModal(
            <Modal title={`Någonting gick fel ${error?.name && ' - ' + error.name}`}>
                <p className="text-sm text-gray-700">
                    {error?.status && `${error?.status} - `}
                    {error?.message}
                </p>
            </Modal>,
        );
    }

    public render() {
        return this.props.children;
    }
}

export default withModal<Props>(ErrorBoundary);
