/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DashboardRequest } from '../models/DashboardRequest';
import type { DashboardResponse } from '../models/DashboardResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class GsStatisticsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @param requestBody
     * @returns DashboardResponse Success
     * @throws ApiError
     */
    public postClubPortalStatisticsDashboard(
        requestBody?: DashboardRequest,
    ): CancelablePromise<DashboardResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/club-portal/statistics/dashboard',
            body: requestBody,
            mediaType: 'application/json-patch+json',
            errors: {
                404: `Not Found`,
            },
        });
    }

}
