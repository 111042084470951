/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum GsPushNotificationConfigType {
    BOOKING_ADDED = 'BookingAdded',
    BOOKING_CANCELLED = 'BookingCancelled',
    BOOKING_REMINDER = 'BookingReminder',
    PLAYER_HAS_LEFT_BOOKING = 'PlayerHasLeftBooking',
    PLAYER_HAS_ARRIVED = 'PlayerHasArrived',
    CLUB_CUSTOM = 'ClubCustom',
}
