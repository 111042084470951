import Modal from 'components/Modal';

export default function LastMinuteHoursHelpModal() {
    return (
        <Modal title="Sista minuten">
            <p className="text-sm text-gray-700">
                Här kan du ange hur många timmar innan en reducerad speltid ska markeras som ett erbjudande.
            </p>
            <br />
            <p className="text-sm text-gray-700">
                Priset sänks <b>inte</b> ytterligare utan kommer endast att marknadsföras som ett erbjudande under din
                klubb i appen. Din klubb kommer även att synas under fliken "Erbjudanden" på startsidan.
            </p>
        </Modal>
    );
}
