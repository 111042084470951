import { ElementType, HTMLAttributes, FC } from 'react';
import classnames from 'classnames';
interface IHeaderProps {
    children?: string;
    as?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'caption' | 'button';
    size?: 'large' | 'medium';
    className?: string;
}

interface ComponentProps extends HTMLAttributes<HTMLOrSVGElement> {
    as?: ElementType;
}

const Component: FC<ComponentProps> = ({ as: Tag = 'h1', ...otherProps }) => {
    return <Tag {...otherProps} />;
};

const sizeClasses = {
    large: 'text-3xl font-bold tracking-tight text-gray-900',
    medium: 'text-2xl font-medium tracking-tight text-gray-900',
};

export default function Header({ children, as = 'h1', size = 'large', className = '' }: IHeaderProps) {
    return (
        <Component as={as} className={classnames([sizeClasses[size], className])}>
            {children}
        </Component>
    );
}
