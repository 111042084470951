import Input from 'components/Input/Input';
import Button from 'components/Button';
import useForm from 'hooks/useForm';
import { Link } from 'react-router-dom';

interface ILoginForm {
    email: string;
    password: string;
}

export default function LoginForm({
    onSubmit,
    isLoading,
    error,
    setError,
}: {
    onSubmit: (values: ILoginForm) => void;
    isLoading: boolean;
    error?: string;
    setError: (error?: string) => void;
}) {
    const { handleValueChange, handleSubmit, formValues } = useForm<ILoginForm>(
        {
            email: '',
            password: '',
        },
        {
            onSubmit: async () => {
                onSubmit(formValues);
            },
        },
    );
    return (
        <form onSubmit={handleSubmit} className="flex flex-col">
            <div className=" flex flex-col gap-2">
                <Input
                    label="E-postadress"
                    type="email"
                    value={formValues.email}
                    placeholder="Skriv in din e-postadress"
                    name="email"
                    onChange={(text) => {
                        handleValueChange('email', text);
                        setError(undefined);
                    }}
                />
                <Input
                    label="Lösenord"
                    type="password"
                    value={formValues.password}
                    placeholder="Skriv in ditt lösenord"
                    name="email"
                    onChange={(text) => {
                        handleValueChange('password', text);
                        setError(undefined);
                    }}
                />
            </div>
            <p className="form-error h-6 text-right">{error}</p>

            <div className="flex justify-between items-center">
                <Button name="submit" type="submit" large isLoading={isLoading}>
                    Logga in
                </Button>
                <Link to="/forgot-password" className="text-primary text-sm">
                    Glömt ditt lösenord?
                </Link>
            </div>
        </form>
    );
}
