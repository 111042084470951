import { useState } from 'react';
import { CognitoUser } from '@aws-amplify/auth';
import LoginForm from './LoginForm';
import ForceNewPasswordForm from './ForceNewPasswordForm';
import AuthService from 'services/AuthService';

export default function LoginPage() {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string>();
    const [tempUser, setTempUser] = useState<CognitoUser | undefined>(undefined);

    async function onLoginSubmit(values: { email: string; password: string }) {
        setIsLoading(true);
        try {
            const user = await AuthService.signIn(values.email, values.password);

            if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
                setTempUser(user);
            }
        } catch (e) {
            if (e instanceof Error) {
                setError(e.message);
            }
        } finally {
            setIsLoading(false);
        }
    }

    async function onNewPasswordSubmit(password: string) {
        setIsLoading(true);
        if (!tempUser) {
            return;
        }

        try {
            await AuthService.completeNewPassword(tempUser, password);
        } catch (e) {
            setIsLoading(false);
            if (e instanceof Error) {
                alert(e.message);
            }
        }
    }

    return (
        <main className="flex flex-col items-center justify-center p-8 h-screen">
            <h1 className="text-3xl font-bold mb-2">Golfers Portal</h1>
            <div className="rounded-3xl max-w-lg w-full bg-white p-8">
                {!tempUser ? (
                    <LoginForm onSubmit={onLoginSubmit} isLoading={isLoading} error={error} setError={setError} />
                ) : (
                    <ForceNewPasswordForm
                        onSubmit={onNewPasswordSubmit}
                        isLoading={isLoading}
                        error={error}
                        setError={setError}
                    />
                )}
            </div>
        </main>
    );
}
