/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GsCourseDto } from '../models/GsCourseDto';
import type { GsUpdateCourseRequest } from '../models/GsUpdateCourseRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class GsCourseService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @param id
     * @returns GsCourseDto Success
     * @throws ApiError
     */
    public getClubPortalCourses(
        id: number,
    ): CancelablePromise<GsCourseDto> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/club-portal/courses/{id}',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
            },
        });
    }

    /**
     * @param id
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public putClubPortalCourses(
        id: number,
        requestBody?: GsUpdateCourseRequest,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/club-portal/courses/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json-patch+json',
            errors: {
                400: `Bad Request`,
                403: `Forbidden`,
            },
        });
    }

}
