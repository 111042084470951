import Modal from 'components/Modal';

export default function IndexWeekdaysHelpModal() {
    return (
        <Modal title="Brytgräns veckodagar">
            <p className="text-sm text-gray-700">
                Brytpunkten anges som procent av banans beläggning då priset ska börja att ökas eller minskas.
            </p>
            <br />
            <p className="text-sm text-gray-700">
                Detta betyder i verkligheten att om en bana har nära beläggningen som anges här så kommer tiderna att
                säljas till standardpris, om det är få sålda tider så kommer priserna att sänkas aningen och om det är
                många bokade tider så kommer priserna att ökas aningen.
            </p>
            <br />
            <p className="text-sm text-gray-700">
                Priserna kommer aldrig att anpassas så att de blir lägre eller högre än de gränsvärden som du satt upp.
            </p>
        </Modal>
    );
}
