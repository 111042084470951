import { createContext, useContext } from 'react';
import { GsMeResponse } from 'api/openapi-club';
interface IAuthenticatedUserContext {
    user: GsMeResponse;
    roles: string[];
}

export const AuthenticatedUserContext = createContext<IAuthenticatedUserContext>(undefined!);

export function useAuthenticatedUser() {
    const c = useContext(AuthenticatedUserContext);
    if (c === undefined) throw new Error('useAuthenticatedUser must be inside a Provider with a value');
    return c;
}
