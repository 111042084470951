import './Input.css';
import { useState } from 'react';
import DatePicker, { InputDatePickerProps } from './InputDatePicker';
import classnames from 'classnames';

export default function InputDateTimePicker({
    onChange,
    selected,
    disabled,
    name,
    minDate,
    maxDate,
    label,
    ...rest
}: InputDatePickerProps) {
    const [date, setDate] = useState(selected);

    const onChangeDate: (dateObj: Date, event: React.SyntheticEvent<any, Event> | undefined) => void = (dateObj) => {
        if (dateObj) {
            setDate(dateObj);
        }
        onChange(dateObj, undefined);
    };

    const onChangeTime: (dateObj: Date, event: React.SyntheticEvent<any, Event> | undefined) => void = (dateObj) => {
        if (dateObj) {
            const newDate = new Date(date ? date : new Date());
            newDate.setHours(dateObj.getHours());
            newDate.setMinutes(dateObj.getMinutes());
            setDate(newDate);
            onChange(newDate, undefined);
        }
    };

    return (
        <div className="datetime-picker">
            <div className="datetime-picker__date-wrapper">
                <DatePicker
                    label={label}
                    dateFormat="P"
                    className={classnames('input__control', disabled && 'input__control--disabled')}
                    locale="sv"
                    autoComplete="off"
                    placeholderText="YYYY-mm-dd"
                    disabled={disabled}
                    maxDate={maxDate}
                    shouldCloseOnSelect
                    minDate={minDate}
                    name={`${name}-date`}
                    selected={date}
                    onChange={onChangeDate}
                    {...rest}
                />
            </div>
            <div className="datetime-picker__time-wrapper">
                <DatePicker
                    dateFormat="HH:mm"
                    className={classnames('input__control', disabled && 'input__control--disabled')}
                    autoComplete="off"
                    placeholderText="HH:mm"
                    disabled={disabled}
                    shouldCloseOnSelect
                    locale="sv"
                    name={`${name}-time`}
                    selected={date}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={15}
                    timeCaption="Tid"
                    onChange={onChangeTime}
                />
            </div>
        </div>
    );
}
