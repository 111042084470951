import DatePicker, { ReactDatePickerProps } from 'react-datepicker';
import './Input.css';
import 'react-datepicker/dist/react-datepicker.css';
import classnames from 'classnames';
export interface InputDatePickerProps extends ReactDatePickerProps {
    placeholder?: string;
    disabled?: boolean;
    name: string;
    label?: string;
}

export default function InputDatePicker({
    selected,
    onChange,
    disabled = false,
    name,
    label,
    ...props
}: InputDatePickerProps) {
    return (
        <div className="input-container">
            {label && <label className="input__label">{label}</label>}
            <DatePicker
                className={classnames('input__control', disabled && 'input__control--disabled')}
                selected={selected}
                disabled={disabled}
                name={name}
                onChange={onChange}
                {...props}
            />
        </div>
    );
}
