import React from 'react';
import { type TabViewsProps } from './TabViews';
import TabList, { type TabListProps } from './TabList';

interface TabsProps {
    children: React.ReactElement<TabListProps | TabViewsProps>[];
}

export default function Tabs({ children }: TabsProps) {
    const [selectedIndex, setSelectedIndex] = React.useState(0);

    const childrenWithProps = React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
            return React.cloneElement(child, {
                selectedIndex,
                /**
                 * Add onClick handler to TabList
                 */
                ...(child.type === TabList && {
                    onClick: (index: number) => {
                        setSelectedIndex(index);
                    },
                }),
            });
        }
        return child;
    });

    return <div>{childrenWithProps}</div>;
}
