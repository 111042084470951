import { useState, useEffect, useRef } from 'react';
import { GsUpdateClubRequest } from 'api/openapi-club';
import Header from 'components/Header';
import { queryClient } from 'App';
import { useQuery, useMutation } from '@tanstack/react-query';
import S3FileService from 'services/S3FileService';
import ApiClient from 'api/ApiClient';
import 'react-image-crop/dist/ReactCrop.css';
import ImagePicker from './ImagePicker';
import Button from 'components/Button';
import ClubSettingsForm from 'forms/ClubSettingsForm';

export default function ClubSettingsPage() {
    const [isEditingImage, setIsEditingImage] = useState(false);
    const [imageUrl, setImageUrl] = useState<string>();
    const previewCanvasRef = useRef<HTMLCanvasElement>(null);

    const { data: club } = useQuery(['club'], () => ApiClient.gsClub.getClubPortalClub(), {
        onError: (error) => {
            throw error;
        },
    });

    useEffect(() => {
        if (!club || !club.bannerImagePath) {
            return;
        }
        S3FileService.getSignedUrl(club.bannerImagePath)
            .then((url) => {
                setImageUrl(url);
            })
            .catch((error) => {
                throw error;
            });
    }, [club]);

    const saveImageMutation = useMutation(
        (payload: File) => {
            return ApiClient.gsClub.postClubPortalClubImage(club?.id!, { file: payload });
        },
        {
            onSuccess: (data) => {
                setIsEditingImage(false);
            },
            onError: (error) => {
                throw error;
            },
        },
    );

    const saveClubMutation = useMutation(
        (payload: GsUpdateClubRequest) => {
            return ApiClient.gsClub.putClubPortalClub(club?.id!, payload);
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['club'], { exact: true });
                setIsEditingImage(false);
            },
            onError: (error) => {
                setIsEditingImage(false);
                throw error;
            },
        },
    );

    if (!club) {
        return <></>;
    }

    async function saveImage() {
        if (!previewCanvasRef || !previewCanvasRef.current) {
            return;
        }
        var dataURL = previewCanvasRef.current.toDataURL('image/jpeg', 0.8);

        setImageUrl(dataURL);

        previewCanvasRef.current.toBlob(
            async (blob) => {
                if (!blob) {
                    return;
                }

                const file = new File([blob], 'image.jpg', { type: 'image/jpeg' });
                saveImageMutation.mutate(file);
            },
            'image/jpeg',
            0.8,
        );
    }

    return (
        <main>
            <div className="max-w-7xl py-6">
                <Header className="lh">{club.name!}</Header>
            </div>
            <div className="shadow bg-white rounded p-5 items-center mb-8">
                <div className="flex gap-6 items-center mb-4 justify-between">
                    <div className="w-full">
                        <h3 className="text-base font-medium">Klubbens bild</h3>
                        <p className="text-gray-500 font-normal text-sm">
                            Omslagsbild i MGBoknings resultatlista och bokningar
                        </p>
                    </div>
                    {!isEditingImage && (
                        <div>
                            <Button onClick={() => setIsEditingImage(true)}>Ändra bild</Button>
                        </div>
                    )}

                    {isEditingImage && (
                        <div className="flex gap-2">
                            <Button onClick={saveImage} isLoading={saveImageMutation.isLoading}>
                                Spara bild
                            </Button>
                            <Button
                                type="submit"
                                variant="warning"
                                disabled={saveImageMutation.isLoading}
                                onClick={() => setIsEditingImage(false)}
                            >
                                Ångra
                            </Button>
                        </div>
                    )}
                </div>

                {isEditingImage ? (
                    <ImagePicker
                        isEditingImage={isEditingImage}
                        previewCanvasRef={previewCanvasRef}
                        defaultImageUrl={imageUrl}
                        onSave={saveImageMutation.mutate}
                    />
                ) : (
                    <div className="flex items-center w-full">
                        <img src={imageUrl} alt="Klubbens bild" className="w-full max-w-[1200px]" />
                    </div>
                )}
            </div>

            <div className="shadow bg-white rounded p-5 items-center mb-8">
                <ClubSettingsForm club={club} onSubmit={saveClubMutation.mutate} />
            </div>
        </main>
    );
}
