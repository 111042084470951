import React, { useCallback } from 'react';
import isEqual from 'lodash.isequal';

import { useCustomCompareCallback } from 'use-custom-compare';

type FieldValues = Record<string, any>;
interface IValidation<T> {
    nullable?: boolean;
    tests?: Test<T>[];
}

type IValidationArray<T> = {
    [key in keyof T]: IValidation<T>;
};

interface Test<T> {
    regex?: RegExp;
    errorString?: string;
    func?: (value: any, formValues: T) => boolean;
}

type IError = {
    [key: string]: string;
};
interface IUseFormOptions<T> {
    validation?: Partial<IValidationArray<T>>;
    onSubmit: () => Promise<void>;
}

export default function useForm<TFieldValues extends FieldValues>(
    initialState: TFieldValues,
    { onSubmit, validation }: IUseFormOptions<TFieldValues>,
) {
    const [isDirty, setIsDirty] = React.useState(false);
    const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);
    const [errors, setErrors] = React.useState<IError>({});

    const [formValues, setFormValues] = React.useReducer(
        (currentValues: TFieldValues, newValues: Partial<FieldValues>) => ({
            ...currentValues,
            ...newValues,
        }),
        initialState,
    );

    React.useEffect(() => {
        setIsDirty(!isEqual(initialState, formValues));
    }, [formValues, initialState]);

    const validateField = React.useCallback(
        (name: keyof TFieldValues, value: any) => {
            if (!validation) {
                return true;
            }

            const fieldValidation = validation[name];

            if (!fieldValidation) {
                return true;
            }

            if (fieldValidation.nullable && value === null) {
                return true;
            }

            if (fieldValidation.tests) {
                for (const test of fieldValidation.tests) {
                    if (test.regex && !test.regex.test(value)) {
                        return test.errorString;
                    }

                    if (test.func && !test.func(value, formValues)) {
                        return test.errorString;
                    }
                }
            }

            return true;
        },
        [formValues, validation],
    );

    const handleValueChange = React.useCallback(
        (name: keyof TFieldValues, value: any) => {
            setFormValues({ [name]: value });

            const errorString = validateField(name, value);

            if (errorString !== true && errorString) {
                setErrors((currentErrors) => ({ ...currentErrors, [name]: errorString }));
            } else {
                setErrors((currentErrors) => {
                    const { [name]: _, ...rest } = currentErrors;
                    return rest;
                });
            }
        },
        [validateField],
    );

    const reset = useCustomCompareCallback(
        () => {
            setFormValues(initialState);
        },
        [initialState],
        (prev, next) => isEqual(prev, next),
    );

    function isValid() {
        return Object.entries(formValues).every((entry) => {
            const [key, value] = entry;

            if (!validation) {
                return true;
            }
            // skip if formvalue doesn't have a test
            if (!validation[key]) {
                return true;
            }

            return validateField(key, value) === true;
            //  validateKey(key as keyof TFieldValues, value);
        });
    }

    const validateValues = useCallback(() => {
        if (!validation) {
            return true;
        }

        let errorArray: IError = {};

        const addError = (k: string, test: Test<TFieldValues>) => {
            errorArray = {
                ...errorArray,
                [k]: test.errorString || '',
            };
        };

        Object.entries(formValues).forEach((entry) => {
            const [key, value] = entry;

            // skip if formvalue doesn't have a test
            if (!validation[key]) {
                return;
            }

            //skip if formvalue has a test, but is null and is nullable
            if (!value && validation[key]?.nullable) {
                return;
            }

            //get tests from test array or from regex param
            validation[key]?.tests?.forEach((test) => {
                //test value against regex if exist
                if (test.regex && !value.match(test.regex)) {
                    addError(key, test);
                }
                //test value against custom function
                if (test.func && !test.func(value, formValues)) {
                    addError(key, test);
                }
            });
        });

        setErrors(errorArray);
        if (Object.keys(errorArray).length) {
            return false;
        }

        return true;
    }, [formValues, validation]);

    async function handleSubmit(e: React.FormEvent) {
        e.preventDefault();

        if (!validateValues()) {
            return false;
        }

        setIsSubmitting(true);

        try {
            await onSubmit();
        } catch (e) {
        } finally {
            setIsSubmitting(false);
        }
    }

    return {
        formValues,
        isSubmitting,
        isDirty,
        reset,
        isValid: isValid(),
        errors,
        setFormValues,
        handleValueChange,
        handleSubmit,
    };
}
