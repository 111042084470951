import { createContext, useContext } from 'react';
import useQueryParam from 'hooks/useQueryParam';

interface LayoutProviderProps {
    children: React.ReactNode;
}

interface ILayoutContext {
    isEmbedded: boolean;
}

const LayoutContext = createContext<ILayoutContext>({ isEmbedded: false });

export function useLayout() {
    return useContext(LayoutContext);
}
export default function LayoutProvider({ children }: LayoutProviderProps) {
    const sessionEmbedded = sessionStorage.getItem('embedded');
    const queryEmbedded = useQueryParam('embedded') === 'true';

    if (sessionEmbedded === null && queryEmbedded) {
        sessionStorage.setItem('embedded', 'true');
    }

    const isEmbedded = sessionEmbedded === 'true' || queryEmbedded;

    return <LayoutContext.Provider value={{ isEmbedded }}>{children}</LayoutContext.Provider>;
}
