/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GsMeResponse } from '../models/GsMeResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class GsAccountService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns GsMeResponse Success
     * @throws ApiError
     */
    public getClubPortalAccount(): CancelablePromise<GsMeResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/club-portal/account',
            errors: {
                404: `Not Found`,
            },
        });
    }

}
