import Modal from 'components/Modal';

export default function CapacityHelpModal() {
    return (
        <Modal title="Capacitet">
            <p className="text-sm text-gray-700">
                Kapaciteten på banan anges i antal rundor per säsong och beskriver hur många spelade rundor som banan
                klarar av.
            </p>
            <br />
            <p className="text-sm text-gray-700">
                Detta värde används för att sätta ett högre pris på greenfee när banan är nära maxkapacitet eller ett
                lite lägre pris för att öka antalet bokningar på banan.
            </p>
        </Modal>
    );
}
