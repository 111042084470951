/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GsCreateDynamicPricingRequest } from '../models/GsCreateDynamicPricingRequest';
import type { GsDynamicPricingSettingsDto } from '../models/GsDynamicPricingSettingsDto';
import type { GsUpdateDynamicPricingRequest } from '../models/GsUpdateDynamicPricingRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class GsDynamicPricingService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @param requestBody
     * @returns GsDynamicPricingSettingsDto Success
     * @throws ApiError
     */
    public getClubPortalDynamicPricingSettings(
        requestBody?: GsCreateDynamicPricingRequest,
    ): CancelablePromise<GsDynamicPricingSettingsDto> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/club-portal/dynamic-pricing/settings',
            body: requestBody,
            mediaType: 'application/json-patch+json',
            errors: {
                400: `Bad Request`,
            },
        });
    }

    /**
     * @param requestBody
     * @returns GsDynamicPricingSettingsDto Success
     * @throws ApiError
     */
    public postClubPortalDynamicPricingSettings(
        requestBody?: GsCreateDynamicPricingRequest,
    ): CancelablePromise<GsDynamicPricingSettingsDto> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/club-portal/dynamic-pricing/settings',
            body: requestBody,
            mediaType: 'application/json-patch+json',
            errors: {
                400: `Bad Request`,
            },
        });
    }

    /**
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public putClubPortalDynamicPricingSettings(
        requestBody?: GsUpdateDynamicPricingRequest,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/club-portal/dynamic-pricing/settings',
            body: requestBody,
            mediaType: 'application/json-patch+json',
            errors: {
                400: `Bad Request`,
            },
        });
    }

}
