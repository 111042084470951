import { GsCourseDto, GsCreateClubPushNotificationConfigRequest, GsBookingSegmentType } from 'api/openapi-club';
import Button from 'components/Button';
import useForm from 'hooks/useForm';
import Header from 'components/Header';
import { useEffect, useState } from 'react';
import ApiClient from 'api/ApiClient';
import TextArea from 'components/TextArea/TextArea';
import Input, { DateTimePicker } from 'components/Input';
import Notification from 'components/Notification/Notification';
import dateHelpers from 'helpers/dateHelpers';
import Section from 'compositions/Section';
import Switch from 'components/Switch';

import 'layout/Layout.css';

interface ICreatePushForm {
    title: string;
    message: string;
    when?: Date | null;
    segmentType: GsBookingSegmentType;
    bookingStartTimeFrom: Date | null;
    bookingStartTimeTo: Date | null;
    courseIds?: number[] | null;
    isScheduled: boolean;
}

interface ICreatePushFormProps {
    onSubmit: (values: GsCreateClubPushNotificationConfigRequest) => void;
    courses: GsCourseDto[];
    isLoading: boolean;
}

export default function CreatePushForm({ courses, onSubmit, isLoading }: ICreatePushFormProps) {
    const [recipientCount, setRecipientCount] = useState(0);
    const [selectCourses, setSelectCourses] = useState(false);
    const { handleValueChange, handleSubmit, formValues, errors } = useForm<ICreatePushForm>(
        {
            title: '',
            message: '',
            when: null,
            bookingStartTimeFrom: null,
            bookingStartTimeTo: null,
            segmentType: GsBookingSegmentType.ALL,
            isScheduled: false,
            courseIds: courses!.map((course) => course.id!),
        },
        {
            onSubmit: async () => {
                try {
                    const payload = getPayloadFromFormValues(formValues);
                    onSubmit(payload);
                } catch (e) {
                    if (e instanceof Error) {
                        alert(e.message);
                    }
                }
                return Promise.resolve();
            },
            validation: {
                title: {
                    tests: [
                        {
                            func: (value) => {
                                return value.length > 0;
                            },
                            errorString: 'Notisen behöver en titel',
                        },
                    ],
                },
                message: {
                    tests: [
                        {
                            func: (value) => {
                                return value.length > 0;
                            },
                            errorString: 'Notisen behöver ett meddelande',
                        },
                    ],
                },
                when: {
                    nullable: false,
                    tests: [
                        {
                            errorString: 'Välj ett datum',
                            func: (value: Date | null, formValues) => {
                                if (formValues.isScheduled && !value) {
                                    return false;
                                }
                                return true;
                            },
                        },
                        {
                            errorString: 'Välj ett datum i framtiden',
                            func: (value: Date | null, formValues) => {
                                if (!value) {
                                    return true;
                                }

                                if (formValues.isScheduled && value < new Date()) {
                                    return false;
                                }

                                return true;
                            },
                        },
                    ],
                },
                bookingStartTimeFrom: {
                    tests: [
                        {
                            errorString: 'Välj ett startdatum',
                            func: (value: Date | null) => {
                                return !!value;
                            },
                        },
                        {
                            errorString: 'Startdatum kan inte vara efter slutdatum',
                            func: (value, formValues) => {
                                if (formValues.bookingStartTimeTo && value) {
                                    return formValues.bookingStartTimeTo >= value;
                                }
                                return true;
                            },
                        },
                    ],
                },
                bookingStartTimeTo: {
                    tests: [
                        {
                            errorString: 'Välj ett slutdatum',
                            func: (value: Date | null) => {
                                return !!value;
                            },
                        },
                        {
                            errorString: 'Slutdatum kan inte vara före startdatum',
                            func: (value, formValues) => {
                                if (formValues.bookingStartTimeFrom && value) {
                                    return formValues.bookingStartTimeFrom <= value;
                                }
                                return true;
                            },
                        },
                    ],
                },
            },
        },
    );

    function getPayloadFromFormValues(formValues: ICreatePushForm): GsCreateClubPushNotificationConfigRequest {
        const payload: GsCreateClubPushNotificationConfigRequest = {
            title: formValues.title,

            message: formValues.message,
            bookingSegment: {
                type: formValues.segmentType,
                startTimeFrom: formValues.bookingStartTimeFrom?.toISOString()!,
                startTimeTo: formValues.bookingStartTimeTo?.toISOString()!,
                courseIds: formValues.courseIds && formValues.courseIds,
            },

            when: formValues.isScheduled && formValues.when ? formValues.when.toISOString() : undefined,
        };

        return payload;
    }

    useEffect(() => {
        if (formValues.bookingStartTimeFrom && formValues.bookingStartTimeTo && formValues.segmentType) {
            fetchRecipientCount(formValues);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formValues.courseIds, formValues.segmentType, formValues.bookingStartTimeFrom, formValues.bookingStartTimeTo]);

    async function fetchRecipientCount(formValues: ICreatePushForm) {
        const payload = getPayloadFromFormValues(formValues);
        const recipientCount = await ApiClient.gsNotification.postClubPortalNotificationsRecievers(
            payload.bookingSegment,
        );
        setRecipientCount(recipientCount);
    }

    function lastMonth(): Date {
        var date = new Date();
        date.setMonth(date.getMonth() - 1);
        return date;
    }
    function nextMonth(): Date {
        var date = new Date();
        date.setMonth(date.getMonth() + 1);
        return date;
    }

    function isAllCoursesSelected() {
        return courses?.every((course) => formValues.courseIds?.includes(course.id!)) || false;
    }

    function getSummaryString() {
        const recipientsMap: { [key in GsBookingSegmentType]: any } = {
            All: 'spelare',
            Guests: 'gäster',
            Members: 'medlemmar',
        };

        const recipientsString = `${recipientCount} ${recipientsMap[formValues.segmentType!]}`;
        const courseString = isAllCoursesSelected()
            ? 'alla banor'
            : formValues.courseIds?.map((id) => courses?.find((course) => course.id === id)?.courseName).join(', ');

        const dateRangeString = `${dateHelpers.getReadableDateTimeString(
            formValues.bookingStartTimeFrom!,
        )} och  ${dateHelpers.getReadableDateTimeString(formValues.bookingStartTimeTo!)}`;

        return (
            <p>
                Skickas till <b>{recipientsString}</b>
                <br /> med bokningar på <b>{courseString}</b>
                <br /> mellan {dateRangeString}
            </p>
        );
    }

    return (
        <form onSubmit={handleSubmit}>
            <div className="shadow bg-white rounded p-5 items-center mb-8">
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Header size="medium">Mottagare</Header>
                    <p>Mottagare: {recipientCount}</p>
                </div>
                <hr className="my-6" />

                <Section title="Datumspann på bokningar" description="Välj vilka spelarbokningar som notisen gäller" />
                <div className="flex gap-6 pt-6">
                    <div>
                        <DateTimePicker
                            label="Bokningar från"
                            name="bookingStartTimeFrom"
                            selectsStart
                            startDate={formValues.bookingStartTimeFrom}
                            endDate={formValues.bookingStartTimeTo}
                            maxDate={nextMonth()}
                            minDate={lastMonth()}
                            onChange={(date) => {
                                handleValueChange('bookingStartTimeFrom', date);
                            }}
                            selected={formValues.bookingStartTimeFrom}
                        />
                        {errors['bookingStartTimeFrom'] && (
                            <p className="form-error">{errors['bookingStartTimeFrom']}</p>
                        )}
                    </div>
                    <div>
                        <DateTimePicker
                            label="Bokningar Till"
                            selectsEnd
                            startDate={formValues.bookingStartTimeFrom}
                            endDate={formValues.bookingStartTimeTo}
                            maxDate={nextMonth()}
                            minDate={lastMonth()}
                            name="bookingStartTimeTo"
                            onChange={(date) => handleValueChange('bookingStartTimeTo', date)}
                            selected={formValues.bookingStartTimeTo}
                        />
                        {errors['bookingStartTimeTo'] && <p className="form-error">{errors['bookingStartTimeTo']}</p>}
                    </div>
                </div>
                <hr className="my-6" />
                <Section
                    title="Gäster och/eller medlemmar"
                    description="Välj om notisen ska gå ut till alla spelare eller en specifik grupp"
                />
                <div className="flex gap-6 pt-6">
                    <Switch
                        id="segmentType-all"
                        label="Alla spelare"
                        checked={formValues.segmentType === GsBookingSegmentType.ALL}
                        onChange={(checked) => {
                            if (checked) {
                                handleValueChange('segmentType', GsBookingSegmentType.ALL);
                            }
                        }}
                    />
                    <Switch
                        id="segmentType-guests"
                        label="Endast gäster"
                        checked={formValues.segmentType === GsBookingSegmentType.GUESTS}
                        onChange={(checked) => {
                            if (checked) {
                                handleValueChange('segmentType', GsBookingSegmentType.GUESTS);
                            }
                        }}
                    />
                    <Switch
                        id="segmentType-members"
                        label="Endast medlemmar"
                        checked={formValues.segmentType === GsBookingSegmentType.MEMBERS}
                        onChange={(checked) => {
                            if (checked) {
                                handleValueChange('segmentType', GsBookingSegmentType.MEMBERS);
                            }
                        }}
                    />
                </div>
                <hr className="my-6" />
                <Section title="Banor" description="Välj vilka banor som notisen ska gälla" />

                <div className="flex gap-6 pt-6">
                    <Switch
                        label="Alla"
                        checked={isAllCoursesSelected()}
                        id={'courseIds-all'}
                        onChange={(checked) => {
                            if (checked) {
                                const values = courses?.map((course) => course.id);
                                handleValueChange('courseIds', values);
                            } else {
                                handleValueChange('courseIds', []);
                            }
                        }}
                    />
                    <Switch
                        label="Välj banor"
                        checked={selectCourses}
                        id={'selectCourses'}
                        onChange={setSelectCourses}
                    />
                </div>
                {selectCourses && (
                    <div className="flex gap-6 pt-6 flex-wrap">
                        {courses?.map((course) => (
                            <Switch
                                key={course.id}
                                label={course.courseName || ''}
                                checked={formValues.courseIds?.includes(course.id!) || false}
                                id={`course-${course.id}`}
                                onChange={(checked) => {
                                    const courseIds = formValues.courseIds ? [...formValues.courseIds] : [];
                                    if (checked) {
                                        courseIds.push(course.id!);
                                    } else {
                                        courseIds.splice(courseIds.indexOf(course.id!), 1);
                                    }
                                    handleValueChange('courseIds', courseIds);
                                }}
                            />
                        ))}
                    </div>
                )}
            </div>
            <div className="shadow bg-white rounded p-5 items-center mb-8">
                <Header size="medium">Meddelande</Header>
                <hr className="my-6" />

                <div style={{ display: 'flex', gap: '3em' }}>
                    <div style={{ flex: 1 }}>
                        <Input
                            label="Rubrik"
                            name="title"
                            placeholder="Skriv in rubrik"
                            onChange={(val) => {
                                handleValueChange('title', val);
                            }}
                        />
                        <p className="form-error">{errors['title']}</p>
                        <p>{`${formValues.title.length} / 48 tecken`}</p>

                        <div style={{ marginTop: '2em' }}>
                            <TextArea
                                label="Meddelande"
                                placeholder="Skriv in ett meddelande"
                                onChange={(e) => {
                                    handleValueChange('message', e.target.value);
                                }}
                            />
                            <p className="form-error">{errors['message']}</p>
                        </div>
                        <p>{`${formValues.message.length} tecken`}</p>
                    </div>
                </div>
            </div>
            <div className="shadow bg-white rounded p-5 items-center mb-8">
                <Header size="medium">Tid för utskick</Header>
                <div className="flex gap-6 pt-6">
                    <Switch
                        id="now"
                        label="Schemalagd"
                        checked={formValues.isScheduled}
                        onChange={(isChecked) => handleValueChange('isScheduled', isChecked)}
                    />
                </div>
                <div style={{ maxWidth: '400px' }} className="mt-6">
                    <DateTimePicker
                        label="Datum och tid"
                        name="when"
                        disabled={!formValues.isScheduled}
                        minDate={new Date()}
                        onChange={(date) => {
                            handleValueChange('when', date);
                        }}
                    />
                    <p className="form-error">{errors.when}</p>
                </div>
            </div>
            <div className="shadow bg-white rounded p-5 items-center mb-8">
                <Header size="medium">Förhandsgranska</Header>
                <hr className="my-6" />
                <div className="flex gap-6 justify-between flex-">
                    {formValues.bookingStartTimeFrom && formValues.bookingStartTimeTo && getSummaryString()}

                    <Notification title={formValues.title} message={formValues.message} />
                </div>
                <hr className="my-6" />
                <div className="flex items-end">
                    <Button type="submit" disabled={isLoading} isLoading={isLoading} large>
                        {!formValues.isScheduled ? 'Skicka' : 'Spara'}
                    </Button>
                </div>
            </div>
        </form>
    );
}
