import React, { createContext, useContext, useCallback } from 'react';
import { useState } from 'react';
interface IModalContext {
    showModal(component: ModalType): void;
    hideModal(): void;
}

type ModalType = React.ReactNode;

const ModalContext = createContext<IModalContext>(undefined!);

interface IModalProviderProps {
    children: React.ReactNode;
}

export const ModalProvider = ({ children }: IModalProviderProps) => {
    const [modal, setModal] = useState<ModalType | undefined>();

    const showModal = useCallback((modal: ModalType) => setModal(modal), []);
    const hideModal = useCallback(() => setModal(undefined), []);

    const value = { showModal, hideModal };

    return (
        <ModalContext.Provider value={value}>
            {children}
            {modal && (
                <div
                    className="fixed inset-0"
                    style={{ zIndex: 999999, backgroundColor: 'rgba(0,0,0,0.2)' }}
                    onClick={hideModal}
                >
                    {modal}
                </div>
            )}
        </ModalContext.Provider>
    );
};

export function useModal() {
    const c = useContext(ModalContext);
    if (c === undefined) throw new Error('useModal must be used within a ModalProvider');
    return c;
}

export function withModal<T extends IModalContext = IModalContext>(WrappedComponent: React.ComponentType<T>) {
    const displayName = WrappedComponent.displayName || WrappedComponent.name || 'Component';

    const ComponentWithTheme = (props: Omit<T, keyof IModalContext>) => {
        const modalContextProps = useModal();
        return <WrappedComponent {...modalContextProps} {...(props as T)} />;
    };

    ComponentWithTheme.displayName = `withModal(${displayName})`;

    return ComponentWithTheme;
}
