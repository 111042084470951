import './Avatar.css';

interface AvatarProps {
    src?: string;
    icon?: JSX.Element;
    alt: string;
    size?: 'small' | 'medium' | 'large';
}

export default function Avatar({ src, icon, alt, size = 'medium' }: AvatarProps) {
    const firstLetter = alt.charAt(0).toUpperCase();
    return (
        <div className={`avatar avatar--${size}`}>
            {src && <img src={src} alt={alt} className="avatar__image" />}
            {!src && icon}
            {!src && !icon && <span>{firstLetter}</span>}
        </div>
    );
}
