import Modal from 'components/Modal';

export default function PercentHighHelpModal() {
    return (
        <Modal title="Högsta prishöjning">
            <p className="text-sm text-gray-700">
                Detta värde sätter en gräns på hur mycket en greenfee kan komma att höjas vid en hög beläggning på banan
                och anges i procent av standard greenfee.
            </p>
        </Modal>
    );
}
