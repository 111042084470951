import { useEffect, useRef } from 'react';
import { GsClubDto, GsUpdateClubRequest } from 'api/openapi-club';
import Input from 'components/Input';
import Switch from 'components/Switch';
import Button from 'components/Button';
import useForm from 'hooks/useForm';
import Header from 'components/Header';
import Map, { IMapHandle } from 'components/Map';
import 'layout/Layout.css';

interface IClubSettingsFormProps {
    onSubmit: (values: GsUpdateClubRequest) => void;
    club: GsClubDto;
}

export default function ClubSettingsForm({ club, onSubmit }: IClubSettingsFormProps) {
    const mapRef = useRef<IMapHandle>(null);

    const { formValues, handleSubmit, handleValueChange, isDirty, errors, reset } = useForm(
        {
            arrivalEnabled: club.arrivalEnabled!,
            longitude: club.longitude,
            latitude: club.latitude,
        },
        {
            onSubmit: async () => {
                onSubmit(formValues as GsUpdateClubRequest);
            },
            validation: {
                longitude: {
                    nullable: false,
                    tests: [
                        {
                            func: (value) => {
                                if (value === '') {
                                    return true;
                                }

                                const parsed = parseFloat(value);
                                return !isNaN(value) && parsed >= -180 && parsed <= 180;
                            },
                            errorString: 'Skriv in en giltig longitud',
                        },
                        {
                            func: (value, formValues) => {
                                if (value === '' && formValues.latitude) {
                                    return false;
                                }

                                return true;
                            },
                            errorString: 'Skriv in en longitud',
                        },
                    ],
                },
                latitude: {
                    nullable: false,
                    tests: [
                        {
                            func: (value) => {
                                if (value === '') {
                                    return true;
                                }

                                const parsed = parseFloat(value);
                                return !isNaN(value) && parsed >= -180 && parsed <= 180;
                            },
                            errorString: 'Skriv in en giltig latitud',
                        },
                        {
                            func: (value, formValues) => {
                                if (value === '' && formValues.longitude) {
                                    return false;
                                }

                                return true;
                            },
                            errorString: 'Skriv in en latitud',
                        },
                    ],
                },
            },
        },
    );

    function resetForm() {
        reset();
        mapRef?.current?.reCenter();
    }

    useEffect(() => {
        mapRef?.current?.reCenter();
    }, [club, reset]);

    return (
        <form onSubmit={handleSubmit}>
            <div className="flex items-center mb-6 justify-between">
                <Header size="medium">Inställningar</Header>
                <div className="flex gap-2">
                    <Button type="submit" disabled={!isDirty}>
                        Spara
                    </Button>
                    <Button variant="warning" disabled={!isDirty} onClick={() => resetForm()}>
                        Ångra
                    </Button>
                </div>
            </div>
            <hr className="my-6" />
            <div className="flex gap-6 items-center">
                <div className="w-full">
                    <h3 className="text-base font-medium">Ankomstmarkering</h3>
                    <p className="text-gray-500 font-normal text-sm">
                        Aktivera möjligheten för spelare att ankomstmarkera sig i MGBokning
                    </p>
                </div>
                <div className="w-full">
                    <Switch
                        checked={!!formValues.arrivalEnabled}
                        id={'arrivalEnabled'}
                        label={formValues.arrivalEnabled ? 'Aktiverad' : 'Inaktiverad'}
                        onChange={(checked) => handleValueChange('arrivalEnabled', checked)}
                    />
                </div>
            </div>

            <hr className="my-6" />
            <h3 className="text-base font-medium pb-3">Klubbhusets position</h3>

            <div className="flex items-end mb-6 gap-4">
                <div className="relative">
                    <Input
                        label="Longitud"
                        placeholder="Skriv in longitud"
                        value={formValues.longitude || undefined}
                        onChange={(val) => handleValueChange('longitude', val.replace(',', '.'))}
                        name="longitude"
                    />
                    {errors['longitude'] && <p className="form-error absolute left-0">{errors['longitude']}</p>}
                </div>
                <div className="relative">
                    <Input
                        label="Latitud"
                        placeholder="Skriv in latitud"
                        value={formValues.latitude ? formValues.latitude.toString() : undefined}
                        onChange={(val) => handleValueChange('latitude', val.replace(',', '.'))}
                        name="latitude"
                    />
                    {errors['latitude'] && <p className="form-error absolute left-0">{errors['latitude']}</p>}
                </div>
            </div>

            <Map
                ref={mapRef}
                center={
                    club.latitude && club.longitude
                        ? { lat: parseFloat(club.latitude), lng: parseFloat(club.longitude) }
                        : undefined
                }
                longitude={formValues.longitude ? parseFloat(formValues.longitude) : null}
                latitude={formValues.latitude ? parseFloat(formValues.latitude) : null}
                label={club.name!}
                addNewMarker={true}
                onChangePosition={(latLng) => {
                    handleValueChange('latitude', latLng.lat);
                    handleValueChange('longitude', latLng.lng);
                }}
            />
        </form>
    );
}
