import { useState } from 'react';
import { useAuthenticatedUser } from 'context/AuthenticatedUserContext';
import './Layout.css';
import { NavLink } from 'react-router-dom';
import ListItem from 'components/ListItem';
import Avatar from 'components/Avatar';
import UserPanel from './UserPanel/UserPanel';
import { FaBars } from 'react-icons/fa';
import Button from 'components/Button';
import useMediaQuery from 'hooks/useMediaQuery';
import classNames from 'classnames';
import breakpoints from 'layout/breakpoints';
import golferSwedenLogoSrc from 'assets/golfersweden-logo.png';
import { Link } from 'react-router-dom';

interface AuthenticatedLayoutProps {
    children: React.ReactNode;
}

export default function AuthenticatedLayout({ children }: AuthenticatedLayoutProps) {
    const { user } = useAuthenticatedUser();
    const [showUserPanel, setShowUserPanel] = useState(false);
    const [showMobileMenu, setShowMobileMenu] = useState(false);
    const isMobile = useMediaQuery(breakpoints.mobile);

    const bodyClickListener = (event: MouseEvent) => {
        setShowMobileMenu(false);
    };

    function toggleMobileMenu(value: boolean) {
        setShowMobileMenu(value);

        if (value) {
            document.body.addEventListener('click', bodyClickListener);
        } else {
            document.body.removeEventListener('click', bodyClickListener);
        }
    }

    return (
        <div className="container2">
            <aside
                className={classNames([
                    'menu',
                    isMobile && 'menu--mobile',
                    isMobile && showMobileMenu && 'menu--mobile--open',
                ])}
            >
                <div className="mobile-menu-toggle-button">
                    {isMobile && (
                        <Button
                            variant="outlined"
                            style={{ padding: '0 12px' }}
                            onClick={(e) => {
                                e.stopPropagation();
                                toggleMobileMenu(!showMobileMenu);
                            }}
                        >
                            <FaBars />
                        </Button>
                    )}
                </div>
                <Link to="/">
                    <div className="menu__logo-container">
                        <img className="menu__logo-container__img" src={golferSwedenLogoSrc} alt="Golfers Logo" />
                    </div>
                </Link>

                <nav>
                    <NavLink to="/club-settings" className="nav-link">
                        <ListItem dark>Klubb</ListItem>
                    </NavLink>

                    <NavLink to="/courses" className="nav-link">
                        <ListItem>Banor</ListItem>
                    </NavLink>

                    <NavLink to="/push" className="nav-link">
                        <ListItem>Notiser</ListItem>
                    </NavLink>
                    <NavLink to="/dynamic-pricing" className="nav-link">
                        <ListItem>Dynamisk Prissättning</ListItem>
                    </NavLink>
                </nav>
            </aside>
            <div className="main">
                <div className={classNames(['topbar', isMobile && 'topbar--mobile'])}>
                    <div>
                        <button
                            style={{
                                display: 'flex',
                                backgroundColor: 'rgba(0,0,0,0)',
                                border: 'none',
                                cursor: 'pointer',
                                alignItems: 'center',
                            }}
                            onClick={(e) => {
                                e.stopPropagation();
                                setShowUserPanel(!showUserPanel);
                            }}
                        >
                            <p style={{ paddingRight: '8px' }}>{user.firstname}</p>
                            <Avatar
                                /*src="https://avatars.githubusercontent.com/u/13378059?v=4"*/ alt={user.email || 'P'}
                            />
                        </button>
                        {showUserPanel && <UserPanel onClose={() => setShowUserPanel(false)} />}
                    </div>
                </div>

                <article className="container mx-auto px-2 pt-11 md:px-6">{children}</article>
            </div>
        </div>
    );
}
