import Header from 'components/Header';
import { useAuthenticatedUser } from 'context/AuthenticatedUserContext';

export default function WelcomePage() {
    const { user } = useAuthenticatedUser();
    return (
        <main>
            <div className="shadow bg-white rounded p-5 items-center my-8">
                <div className="max-w-7xl py-6">
                    <Header className="lh">Varmt välkommen till Golfers och klubbportalen!</Header>
                </div>
                <p className="mb-6">Hej {user?.firstname}!</p>
                <p className="mb-6">
                    Svenska Golfförbundet har valt oss som leverantör till den officiella mobilappen för bokning av
                    golftider för de klubbar som är anslutna till SGF. Portalen och mobilapplikationen ingår i
                    GIT-avgiften och innebär ingen extrakostnad varken för dynamisk prissättning eller att erbjuda
                    golfaren ytterligare en väg att boka tider.
                </p>
                <p className="mb-6">
                    I klubbportalen kan ni som klubb vara med och styra hur klubben och era golfbanor visas i våra
                    mobilapplikationer. Ni kan bland annat redigera information om klubben, ladda upp en klubb-bild, och
                    redigera dynamisk prissättning för era golfbanor.
                </p>
                <p className="mb-6">
                    Vår ambition är att hela tiden utveckla portalen och mobilapplikationerna och göra dem bättre. Tveka
                    aldrig med att kontakta oss om det är något ni undrar över eller om ni har idéer och tankar som kan
                    göra klubbportalen eller apparna bättre. Tillsammans kommer vi att skapa en förbättrad
                    användarupplevelse för golfarna!
                </p>
                <p>Varma golfhälsningar, Golfers-teamet</p>
            </div>
        </main>
    );
}
