import './Range.css';
interface IRangeProps {
    value?: number | undefined;
    onChange?: (value: number) => void;
    onBlur?: (value: number) => void;
    disabled?: boolean;
    name: string;
    label?: string | JSX.Element;
    min?: number;
    max?: number;
    defaultValue?: number;
}

export default function Range({
    value,
    onChange = () => {},
    onBlur = () => {},
    disabled = false,
    name,
    label,
    min,
    max,
    defaultValue,
}: IRangeProps) {
    const labelComponent = typeof label === 'string' ? <label className="range__label">{label}</label> : label;
    return (
        <div className="range">
            {label && labelComponent}

            <input
                className="range__control"
                type="range"
                disabled={disabled}
                name={name}
                value={value}
                onBlur={(e) => onBlur(Number(e.currentTarget.value))}
                onChange={(e) => onChange(Number(e.currentTarget.value))}
                min={min}
                max={max}
                defaultValue={defaultValue}
            />
        </div>
    );
}
