import AuthService from '../services/AuthService';
import { ClubClient } from './openapi-club';

async function getHeaders() {
    return {
        'api-key': process.env.REACT_APP_API_KEY,
    };
}

const ApiClient = new ClubClient({
    HEADERS: getHeaders,
    TOKEN: AuthService.getCurrentUserAccessToken,
});

export default ApiClient;
