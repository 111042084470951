/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum GsPushNotificationConfigStatus {
    RECURRING = 'Recurring',
    PENDING = 'Pending',
    SENT = 'Sent',
    DISABLED = 'Disabled',
}
