import { useState, useEffect } from 'react';
import { useQuery, useMutation } from '@tanstack/react-query';
import ApiClient from 'api/ApiClient';
import { queryClient } from 'App';
import { GsCreateDynamicPricingRequest, GsUpdateDynamicPricingRequest } from 'api/openapi-club';
import CourseDynamicPricingForm from 'forms/CourseDynamicPricingForm';
import Header from 'components/Header';
import Button from 'components/Button';

export default function DynamicPricingPage() {
    const [error, setError] = useState<unknown>();
    const { data: courses } = useQuery(['courses'], () => ApiClient.gsClub.getClubPortalClubCourses(), {
        onError: (error) => {
            setError(error);
        },
    });

    const isDynamicPricingEnabledByAdmin = courses?.some((course) => course.dynamicPricingSettings?.enabledByAdmin);

    const createDynamicPricingMutation = useMutation(
        (payload: GsCreateDynamicPricingRequest) => {
            return ApiClient.gsDynamicPricing.postClubPortalDynamicPricingSettings(payload);
        },
        {
            onError: (error) => {
                setError(error);
            },

            onSuccess: () => {
                queryClient.invalidateQueries(['courses'], { exact: true });
            },
        },
    );

    const updateDynamicPricingMutation = useMutation(
        (payload: GsUpdateDynamicPricingRequest) => {
            return ApiClient.gsDynamicPricing.putClubPortalDynamicPricingSettings(payload);
        },
        {
            onError: (error) => {
                setError(error);
            },
            onSuccess: () => {
                queryClient.invalidateQueries(['courses'], { exact: true });
            },
        },
    );

    function openFAQFile() {
        const url = `${process.env.REACT_APP_S3_BASE_URL}${process.env.REACT_APP_S3_BUCKET_NAME}/documents/faq_dynamisk_priss%C3%A4ttning.pdf`;
        window.open(url, '_blank');
    }

    useEffect(() => {
        if (error) {
            throw error;
        }
    }, [error]);

    if (!courses) {
        return <></>;
    }
    return (
        <main>
            <div className="max-w-7xl py-6">
                <Header className="lh">Dynamisk Prissättning</Header>
                {!isDynamicPricingEnabledByAdmin ? (
                    <p>För att aktivera dynamisk prissättning behöver du kontakta supporten.</p>
                ) : (
                    <></>
                )}
            </div>
            <div className="mb-8">
                <p className="mb-2">Klicka här för att ladda ner en FAQ om dynamisk prissättning</p>
                <Button onClick={openFAQFile}>FAQ Dynamisk Prissättning</Button>
            </div>
            {courses
                ?.sort((a, b) => ((a.sorting || 99) > (b.sorting || 99) ? 1 : -1))
                .map((course) => (
                    <div key={course.id} className="shadow bg-white rounded p-5 items-center mb-8">
                        <CourseDynamicPricingForm
                            course={course}
                            onUpdate={updateDynamicPricingMutation.mutateAsync}
                            onCreate={createDynamicPricingMutation.mutateAsync}
                        />
                    </div>
                ))}
        </main>
    );
}
