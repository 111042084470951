import './UserPanel.css';
import { useEffect } from 'react';
import { useAuthenticatedUser } from 'context/AuthenticatedUserContext';
import Avatar from 'components/Avatar';
import Button from 'components/Button';
import AuthService from 'services/AuthService';

export default function UserPanel({ onClose }: { onClose: () => void }) {
    const { user } = useAuthenticatedUser();

    useEffect(() => {
        const escListener = (event: KeyboardEvent) => {
            if (event.key === 'Escape') {
                onClose();
            }
        };
        const bodyClickListener = (event: MouseEvent) => {
            onClose();
        };
        document.addEventListener('keydown', escListener);
        document.body.addEventListener('click', bodyClickListener);

        return () => {
            document.removeEventListener('keydown', escListener);
            document.body.removeEventListener('click', bodyClickListener);
        };
    }, [onClose]);

    return (
        <div className="user-panel" onClick={(e) => e.stopPropagation()}>
            <div className="user-panel__avatar">
                <Avatar alt={user.email || 'P'} size="large" />
            </div>
            <div className="user-panel__name">{user.email}</div>
            <div className="user-panel__name">{user.club?.name}</div>
            <Button onClick={AuthService.signOut} style={{ marginTop: 14 }}>
                Logga ut
            </Button>
        </div>
    );
}
