import Modal from 'components/Modal';

export default function PercentLowHelpModal() {
    return (
        <Modal title="Största prissänkning">
            <p className="text-sm text-gray-700">
                Detta värde sätter en gräns på hur mycket en greenfee kan komma att sänkas vid en låg beläggning på
                banan och anges i procent av standard greenfee.
            </p>
        </Modal>
    );
}
