import { GsCourseDto } from 'api/openapi-club';
import Input from 'components/Input';
import Header from 'components/Header';
import Select from 'components/Select';
import Switch from 'components/Switch';
import Button from 'components/Button';
import useForm from 'hooks/useForm';
import Range from 'components/Range';
import Section from 'compositions/Section';
import { FaInfoCircle } from 'react-icons/fa';
import {
    CapacityHelpModal,
    IndexWeekdaysHelpModal,
    IndexWeekendsHelpModal,
    LastMinuteHoursHelpModal,
    MaxReducedPricesPerBlockHelpModal,
    PercentHighHelpModal,
    PercentLowHelpModal,
} from './Modals';
import { useModal } from 'context/ModalContext';
import 'layout/Layout.css';
import {
    GsUpdateDynamicPricingRequest,
    GsCreateDynamicPricingRequest,
    GsDynamicPricingMonthlySettings,
} from 'api/openapi-club';

interface ICourseDynamicPricingFormProps {
    course: GsCourseDto;
    onCreate: (values: GsCreateDynamicPricingRequest) => void;
    onUpdate: (values: GsUpdateDynamicPricingRequest) => void;
    isSubmitting?: boolean;
    isEdit?: boolean;
}

export default function CourseDynamicPricingForm({ course, onCreate, onUpdate }: ICourseDynamicPricingFormProps) {
    const { showModal } = useModal();
    const { formValues, handleSubmit, handleValueChange, isDirty, reset } = useForm(
        {
            enabled: course.dynamicPricingSettings?.enabled,
            lastMinuteHours: course.dynamicPricingSettings?.lastMinuteHours,
            capacity: course.dynamicPricingSettings?.capacity,
            indexes: course.dynamicPricingSettings?.indexes?.sort((a, b) => (a?.month ?? 0) - (b?.month ?? 0)),
        },
        {
            onSubmit: async () => {
                const payload: GsUpdateDynamicPricingRequest = {
                    ...formValues,
                };
                if (course?.dynamicPricingSettings?.id) {
                    onUpdate({
                        ...course.dynamicPricingSettings,
                        ...payload,
                    });
                } else {
                    onCreate({
                        ...payload,
                        courseId: course.id,
                    });
                }
            },
        },
    );

    function getSettingsForMonth(month: number) {
        if (!formValues.indexes) {
            return;
        }
        return formValues.indexes.find((i) => i.month === month);
    }

    function addNewMonth() {
        let indexes = [...(formValues.indexes || [])];

        const monthSetting: GsDynamicPricingMonthlySettings = {
            active: true,
            month: 0,
            indexWeekdays: 10,
            indexWeekends: 10,
            maxReducedPricesPerBlock: 4,
            percentHigh: 0,
            percentLow: 0,
        };
        indexes.unshift(monthSetting);
        handleValueChange('indexes', indexes);
    }

    function removeMonth(index: number) {
        if (!formValues.indexes) {
            return;
        }

        const indexes = formValues.indexes.filter((priceConfig) => priceConfig.month !== index);
        handleValueChange('indexes', indexes);
    }

    function setParamForMonth(month: number, param: keyof GsDynamicPricingMonthlySettings, value: any) {
        if (!formValues.indexes) {
            return;
        }

        const monthlySettings = getSettingsForMonth(month);
        if (!monthlySettings) {
            return;
        }

        const newIndexes = formValues.indexes.map((index) => {
            if (index.month === month) {
                return { ...index, [param]: value };
            }
            return index;
        });

        handleValueChange('indexes', newIndexes);
    }

    return (
        <form onSubmit={handleSubmit}>
            <div className="flex items-center mb-6 justify-between">
                <Header size="medium">{course.courseName!}</Header>
                <div className="flex gap-2">
                    <Button type="submit" disabled={!isDirty}>
                        Spara
                    </Button>
                    <Button variant="warning" disabled={!isDirty} onClick={() => reset()}>
                        Ångra
                    </Button>
                </div>
            </div>
            <hr className="my-6" />
            <div className="flex gap-6 items-center">
                <div className="w-full">
                    <h3 className="text-base font-medium">Aktivera dynamisk prissättning</h3>
                    <p className="text-gray-500 font-normal text-sm">
                        Aktiverar dynamisk prissättning för denna bana för valda månader
                    </p>
                </div>
                <div className="w-full">
                    <Switch
                        checked={!!formValues.enabled}
                        id={`enabled_${course.id}`}
                        label={formValues.enabled ? 'Aktiverad' : 'Inaktiverad'}
                        onChange={(checked) => {
                            handleValueChange('enabled', checked);
                        }}
                    />
                </div>
            </div>
            <hr className="my-6" />
            <div className="flex gap-6 items-center">
                <div className="w-full">
                    <div className="flex gap-6 items-center">
                        <h3 className="text-base font-medium">Kapacitet</h3>
                        <Button variant="text" onClick={() => showModal(<CapacityHelpModal />)}>
                            <FaInfoCircle size={18} />
                        </Button>
                    </div>

                    <p className="text-gray-500 font-normal text-sm">
                        Maximalt antal rundor per säsong som banan klarar av att ta emot
                    </p>
                </div>
                <div className="w-full">
                    <Input
                        label="0 till 50 000 rundor"
                        name={`capacity_${course.id}`}
                        min={0}
                        max={50000}
                        type="number"
                        placeholder="Antar rundor"
                        value={formValues?.capacity?.toString()}
                        onChange={(val) => handleValueChange('capacity', parseInt(val))}
                    />
                </div>
            </div>
            <hr className="my-6" />
            <div className="flex gap-6 items-center">
                <div className="w-full">
                    <div className="flex gap-4 items-center">
                        <h3 className="text-base font-medium">Antal timmar innan erbjudande</h3>
                        <Button variant="text" onClick={() => showModal(<LastMinuteHoursHelpModal />)}>
                            <FaInfoCircle size={18} />
                        </Button>
                    </div>

                    <p className="text-gray-500 font-normal text-sm">
                        Välj hur många timmar innan en reducerad speltid ska markeras som ett erbjudande
                    </p>
                </div>
                <div className="w-full">
                    <Input
                        label="24 till 72 timmar"
                        name={`time_${course.id}`}
                        min={24}
                        max={72}
                        type="number"
                        placeholder="Antal timmar"
                        value={formValues.lastMinuteHours?.toString()}
                        onChange={(val) => handleValueChange('lastMinuteHours', parseInt(val))}
                    />
                </div>
            </div>

            <hr className="my-6" />

            <div className="w-full justify-between">
                <div className="flex gap-6 items-center mb-2">
                    <h3 className="text-base font-medium">Månader</h3>
                </div>
            </div>

            {(!formValues.indexes || formValues.indexes.length === 0) && (
                <p className="border border-gray-200 border-1 rounded-xl p-6 text-gray-400 mt-6 mb-2">
                    Inga priskonfigurationer är tillagda.
                </p>
            )}

            {formValues.indexes?.map((priceIndex) => (
                <div className="border border-gray-200 border-1 rounded-xl p-6 mt-6 mb-2" key={priceIndex.month}>
                    <div className="flex justify-end w-full mb-6">
                        <Button
                            variant="warning"
                            onClick={() => {
                                if (window.confirm('Är du säker på att du vill ta bort den här månaden?')) {
                                    removeMonth(priceIndex.month!);
                                }
                            }}
                        >
                            Ta bort månad
                        </Button>
                    </div>

                    <Section title="Månad" description="Välj vilken månad som priskonfigurationen gäller">
                        <Select
                            value={priceIndex.month?.toString()}
                            name={`month_${course.id}_${priceIndex.month}`}
                            onChange={(val) => setParamForMonth(priceIndex.month!, 'month', parseInt(val))}
                        >
                            <option value="0" disabled>
                                Välj månad
                            </option>
                            <option value="1">Januari</option>
                            <option value="2">Februari</option>
                            <option value="3">Mars</option>
                            <option value="4">April</option>
                            <option value="5">Maj</option>
                            <option value="6">Juni</option>
                            <option value="7">Juli</option>
                            <option value="8">Augusti</option>
                            <option value="9">September</option>
                            <option value="10">Oktober</option>
                            <option value="11">November</option>
                            <option value="12">December</option>
                        </Select>
                    </Section>

                    <hr className="my-6" />

                    <Section title="Aktiv" description="Aktivera denna månad">
                        <Switch
                            checked={!!priceIndex.active}
                            id={`month_active-${priceIndex.month!}`}
                            label={priceIndex.active ? 'Aktiverad' : 'Inaktiverad'}
                            onChange={(checked) => {
                                setParamForMonth(priceIndex.month!, 'active', checked);
                            }}
                        />
                    </Section>

                    <hr className="my-6" />

                    <Section
                        title="Brytgräns veckodagar (10-70%)"
                        description="Brytpunkten anges som procent av banans beläggning då priset ska börja att ökas eller
                                minskas"
                        readMore={() => showModal(<IndexWeekdaysHelpModal />)}
                    >
                        <Range
                            label={<label className="input__label">{priceIndex?.indexWeekdays || 0}%</label>}
                            name={`index_weekdays_${course.id}_${priceIndex.month}`}
                            value={priceIndex?.indexWeekdays}
                            min={10}
                            max={70}
                            onChange={(val) => setParamForMonth(priceIndex.month!, 'indexWeekdays', val)}
                        />
                    </Section>
                    <hr className="my-6" />
                    <Section
                        title="Brytgräns helger (10-70%)"
                        description="Brytpunkten anges som procent av banans beläggning då priset ska börja att ökas eller
                                minskas"
                        readMore={() => showModal(<IndexWeekendsHelpModal />)}
                    >
                        <Range
                            label={<label className="input__label">{priceIndex?.indexWeekends || 0}%</label>}
                            name={`index_weekends_${course.id}_${priceIndex.month}`}
                            value={priceIndex?.indexWeekends}
                            min={10}
                            max={70}
                            onChange={(val) => setParamForMonth(priceIndex.month!, 'indexWeekends', val)}
                        />
                    </Section>
                    <hr className="my-6" />

                    <Section
                        title="Max antal reducerade tider samtidigt"
                        description="Anger hur många priser som ska kunna vara reducerade samtidigt per dag."
                        readMore={() => showModal(<MaxReducedPricesPerBlockHelpModal />)}
                    >
                        <Select
                            name={`maxReducedPricesPerBlock_${course.id}_${priceIndex.maxReducedPricesPerBlock}`}
                            placeholder="Välj antal tider"
                            label="Antal reducerade tider"
                            value={priceIndex?.maxReducedPricesPerBlock?.toString()}
                            onChange={(val) =>
                                setParamForMonth(priceIndex.month!, 'maxReducedPricesPerBlock', parseInt(val))
                            }
                        >
                            <option value="4">4</option>
                            <option value="8">8</option>
                            <option value="12">12</option>
                            <option value="16">16</option>
                        </Select>
                    </Section>
                    <hr className="my-6" />
                    <Section
                        title="Största prissänkning (0-50%)"
                        description="Välj hur stor procent av greenfeen maximalt kan sänkas"
                        readMore={() => showModal(<PercentLowHelpModal />)}
                    >
                        <Input
                            label="Största prissänkning"
                            value={priceIndex?.percentLow?.toString()}
                            name={`percentLow_${course.id}_${priceIndex.month}`}
                            onChange={(val) => setParamForMonth(priceIndex.month!, 'percentLow', parseInt(val))}
                            type="number"
                            min={0}
                            max={50}
                        />
                    </Section>
                    <hr className="my-6" />
                    <Section
                        title="Största prisökning (0-50%)"
                        description="Välj hur stor procent av greenfeen maximal kan ökas"
                        readMore={() => showModal(<PercentHighHelpModal />)}
                    >
                        <Input
                            label="Största prisökning"
                            name={`percentHigh_${course.id}_${priceIndex.month}`}
                            value={priceIndex?.percentHigh?.toString()}
                            onChange={(val) => setParamForMonth(priceIndex.month!, 'percentHigh', parseInt(val))}
                            type="number"
                            min={0}
                            max={50}
                        />
                    </Section>
                </div>
            ))}

            <div className="w-full ">
                <div className="flex items-center mb-2">
                    {!formValues.indexes?.find((i) => i.month === 0) && (
                        <Button onClick={addNewMonth}>+ Lägg till månad</Button>
                    )}
                </div>
            </div>
        </form>
    );
}
